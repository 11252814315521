import React, { useEffect, useState } from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import './app-form-card-template-selector.scss';
import GiftCard from '../gift-card';
import { GiftCardModel, GiftCardTheme } from '../../models'

interface AppFormCardTemplateSelectorProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  selectedCategory?: string | null;
}

const AppFormCardTemplateSelector = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  selectedCategory,
}: AppFormCardTemplateSelectorProps<TForm, TName>) => {
  const [cards, setCards] = useState<GiftCardModel[]>([]);

  useEffect(() => {
    const loadImages = () => {
      try {
        const cards: GiftCardModel[] = [];

        const card1: GiftCardModel = {  
          theme: GiftCardTheme.DARK,
          backgroundColor: 'black',
          backgroundImage: require('../../assets/images/card-templates-categories/' + selectedCategory + '/template1.png'),
        };
        const card2: GiftCardModel = {  
          theme: GiftCardTheme.DARK,
          backgroundColor: 'black',
          backgroundImage: require('../../assets/images/card-templates-categories/' + selectedCategory + '/template2.png'),
        };

        cards.push(card1);
        cards.push(card2);
        setCards(cards);
      } catch (err) {
        console.error('Error loading images', err);
      }
    };
    loadImages();
  }, [selectedCategory]);
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="app-form-cards-selector-container">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`app-form-card-option ${field.value === card.backgroundImage ? 'selected' : ''}`}
              onClick={() => field.onChange(card.backgroundImage)}
            >
              <GiftCard card={card} />
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default AppFormCardTemplateSelector;
