import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  CompleteTaskRequestPayload,
  ProcessActiveTaskResponse,
  StartProcessRequestPayload,
} from './api.service.types';
import { ProcessId, TaskName } from '../processes/process.types';
import { attachAxiosLogInterceptors, attachAxiosApiInterceptors } from '../utils/axios-utils';
import { UserModel } from '../models';
import { Dispatch } from '@reduxjs/toolkit';

class ApiService {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      // #TODO add to config
      baseURL: 'https://gateway.bloom.realgrowsoft.com',
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      }
    });

    attachAxiosLogInterceptors(this.client);
  }

  async startProcess<T extends ProcessId>(
    processId: T,
    data: StartProcessRequestPayload[T]
  ): Promise<AxiosResponse<number>> {
    const res: AxiosResponse<string> = await this.client.post<string>(
      `/zeebe-broker/startProcess/${processId}`,
      data
    );

    return { ...res, data: +res.data };
  }

  getActiveTask(jobId: number): Promise<AxiosResponse<ProcessActiveTaskResponse>> {
    return this.client.get(`/zeebe-broker/${jobId}/active`);
  }

  completeTask<T extends TaskName>(
    taskName: T,
    humanTaskId: number,
    data: CompleteTaskRequestPayload[T]
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/zeebe-broker/completeUserTask/${taskName}/${humanTaskId}`,
      data
    );
  }

  attachDispatchToAxiosApi(dispatch: Dispatch<any>) {
    attachAxiosApiInterceptors(this.client, dispatch)
  }


  getUserDetails(userId: string): Promise<AxiosResponse<UserModel>> {
    return this.client.get(`/auth/${userId}`)

  }

  async postWhatsAppInvite(phoneNumber: string) {
    await this.client.post(`/core/notifications/sendWhatsappMessage/NEW_LINK_MESSAGE`, {
      receiverPhoneNumber: phoneNumber,
      newMemberLink: "https://web.bloom.realgrowsoft.com/"
    });
  }

  async postEmailInvite(email: string) {
    await this.client.post(`/core/notifications/sendWhatsappMessage/NEW_LINK_MESSAGE`, {
      receiverEmail: email,
      message: "https://web.bloom.realgrowsoft.com/"
    });
  }
}

const apiService = new ApiService();
export { apiService };