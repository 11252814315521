import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button";
import PageTitle from "../../../../components/page-title";
import "./account-created-page.scss"


const AccountCreatedPage: React.FC = () => {
  //TODO change Icon Placeholder 
  const navigate = useNavigate();
  return (
    <div className="page-outer">
      <div className="page-content account-created-page-container">
        <PageTitle text="Done"></PageTitle>
        <div className="flex-column flex-gap-50 flex-align-center-center account-created-content">
          <div className="flex-column flex-gap-10 flex-align-center-center account-text-icon-container">
            <div className="icon-placeholder"></div>  
            <p className="account-created-primary-text">Account has been <br /> successfully created</p>
            <p className="account-created-secondary-text">Your account is now ready to use. You can access your dashboard to start exploring the features and managing your settings.</p>
          </div>
          <Button onClick={() => {navigate('/account/profile') }} theme="outline" label="Go to Dashboard" />
        </div>
      </div>
    </div>
  )
}

export default AccountCreatedPage;