import LineSeparator from "../../../../components/line-separator";
import "./catalogue-page.part.shopping-options-menu.scss";
import CataloguePartMultipleCheckbox from "./catalogue-page.part.multiple-checkbox";
import { useState } from "react";



const deliveryTypeOptions = [
    {
        label: "Mail (Plastic Gift Card)",
        value: "mail"
    },
    {
        label: "E-mail Standard (eGift Card)",
        value: "email-standard"
    },
    {
        label: "E-mail Animated (eGift Card)",
        value: "email-animated"
    },
]

const cardTypeOptions = [
    {
        label: "Visa Prepaid for Myself",
        value: "visa-myself"
    },
    {
        label: "Visa Prepaid for Gift",
        value: "visa-gift"
    },
    {
        label: "e-Gift for Merchants",
        value: "egift-merchants"
    },
]

const occasionOptions = [
    { label: "Anniversary", value: "anniversary" },
    { label: "Baby", value: "baby" },
    { label: "Birthday", value: "birthday" },
    { label: "Business", value: "business" },
    { label: "Charity", value: "charity" },
    { label: "Congratulations", value: "congratulations" },
    { label: "Father's Day", value: "fathers-day" },
    { label: "Graduation", value: "graduation" },
    { label: "Health & Wellness", value: "health-wellness" },
    { label: "Hobbies & Sports", value: "hobbies-sports" },
    { label: "Housewarming", value: "housewarming" },
    { label: "Mother's Day", value: "mothers-day" },
    { label: "Pets", value: "pets" },
    { label: "Thank You", value: "thank-you" },
    { label: "Travel & Food", value: "travel-food" },
    { label: "Valentine's Day", value: "valentines-day" },
    { label: "Wedding & Engagement", value: "wedding-engagement" },
    { label: "Easter", value: "easter" },
    { label: "Holiday", value: "holiday" }
];






const CataloguePagePartShoppingOptions: React.FC = () => {

    const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState<string[]>([]);
    const [selectedCardTypes, setSelectedCardTypes] = useState<string[]>([]);
    const [selectedOccasions, setSelectedOccasions] = useState<string[]>([]);

    const handleDeliveryTypeChange = (newValues: string[]) => {
        setSelectedDeliveryTypes(newValues);
    };

    const handleCardTypeChange = (newValues: string[]) => {
        setSelectedCardTypes(newValues);
    };

    const handleOccasionChange = (newValues: string[]) => {
        setSelectedOccasions(newValues);
    };



    return (
        <div className="catalogue-side-menu-container">
            <div className="side-menu">
                <h4 className="menu-title">Shopping Options</h4>
                <div className="category-container">
                    <p className="category-header">Delivery Type</p>
                    <LineSeparator />
                    <div className="category-options-container">
                        <CataloguePartMultipleCheckbox
                            options={deliveryTypeOptions}
                            value={selectedDeliveryTypes}
                            onChange={handleDeliveryTypeChange}
                        />
                    </div>
                </div>
                <div className="category-container">
                    <p className="category-header">Card Type</p>
                    <LineSeparator />
                    <div className="category-options-container">
                        <CataloguePartMultipleCheckbox
                            options={cardTypeOptions}
                            value={selectedCardTypes}
                            onChange={handleCardTypeChange}
                        />
                    </div>
                </div><div className="category-container">
                    <p className="category-header">Occasion</p>
                    <LineSeparator />
                    <div className="category-options-container">
                        <CataloguePartMultipleCheckbox
                            options={occasionOptions}
                            value={selectedOccasions}
                            onChange={handleOccasionChange}
                        />
                    </div>
                </div>
            </div>
        </div>)
}


export default CataloguePagePartShoppingOptions