import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonalDetailsFormValue } from '../router/public/pages/register/register-page.step-personal-details';
import { PersonalDetailsVerificationFormValue } from '../router/public/pages/register/register-page.step-verification';

export interface RegisterState {
  personalDetails?: PersonalDetailsFormValue;
  verification?: PersonalDetailsVerificationFormValue;
}

const initialState: RegisterState = {};
const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    savePersonalDetails: (state: RegisterState, action: PayloadAction<PersonalDetailsFormValue>) => {
      state.personalDetails = action.payload;
    },
    saveVerification: (state: RegisterState, action: PayloadAction<PersonalDetailsVerificationFormValue>) => {
      state.verification = action.payload;
    },
  }
});

export const registerReducer = registerSlice.reducer;
export const registerSliceActions = {
  savePersonalDetails: registerSlice.actions.savePersonalDetails,
  saveVerification: registerSlice.actions.saveVerification,
};