import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import Button from '../../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { RegisterState } from '../../../../store/register.slice';
import AppFormInput from '../../../../components/form/app-form-input';
import { processService } from '../../../../processes/process.service';
import { TaskName } from '../../../../processes/process.types';
import { ProcessState } from '../../../../store/process.slice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface VerifyOtpFormValue extends FieldValues {
  otp: string;
}

const RegisterPageStepVerifyOtp: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>();
  const registerState = useSelector<AppStore, RegisterState>(store => store.register);
  const processState = useSelector<AppStore, ProcessState>(store => store.process);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<VerifyOtpFormValue>({
    mode: 'onTouched',
    defaultValues: {}
  });

  const onSubmitHandler = async (data: VerifyOtpFormValue) => {
    setIsLoading(true);

    try {
      await processService.completeTask<TaskName.ADD_OTP>(processState, data, navigate, dispatch);
      setIsValid(true);

      await processService.startNextTask(processState.inProgress?.jobId, navigate, dispatch);
    } catch (e) {
      setIsValid(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppFormSectionTitle
        title={t('pages.register.step.verify-otp.form.title')}
        info={t('pages.register.step.verify-otp.form.info', {sentTo: registerState.verification?.email || registerState.verification?.whatsappNumber})}
      ></AppFormSectionTitle>

      <AppFormInput
        name="otp"
        control={form.control}
        label={t('pages.register.step.verify-otp.form.field.otp.label')}
        validations={{required: true, minLength: 4, maxLength: 4}}
      />

      <div className="flex-row flex-align-space-between-center">
        <span className="status-text">
          {isValid === undefined && 'Resend code in 00:30'}
          {isValid === true && 'Code confirmed'}
          {isValid === false && 'Code invalid'}
        </span>

        {isLoading && (
          <span className="loading">Loading...</span>
        )}
      </div>

      <div className="form-buttons-container">
        <Button
          label={t('layout.buttons.confirm')}
          onClick={form.handleSubmit(onSubmitHandler)}
          theme="accent"
          disabled={!form.formState.isValid}
        ></Button>
      </div>
    </>
  );
};

export default RegisterPageStepVerifyOtp;