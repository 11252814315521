export enum ProcessId {
  REGISTER = 'REGISTER',
  SOCIAL_REGISTER = 'SOCIAL_REGISTER',
  PROFILE_CONFIGURATION = 'PROFILE_CONFIGURATION',
}

export enum TaskName {
  REGISTER = 'REGISTER',
  ADD_OTP = 'ADD_OTP',
  // PROFILE_CONFIGURATION = 'PROFILE_CONFIGURATION',
  ADD_ADDRESS = 'ADD_ADDRESS',
  ADD_PASSWORD = 'ADD_PASSWORD',
  // UPDATE_COMPANY = 'UPDATE_COMPANY',
  // UPDATE_PROFILE = 'UPDATE_PROFILE',
  // UPDATE_ADDRESS = 'UPDATE_ADDRESS',
  UPLOAD_ID = 'UPLOAD_ID',
  UPLOAD_SELFIE = 'UPLOAD_SELFIE',
}

export const taskToUrlMap = {
  [TaskName.REGISTER]: '/register',
  [TaskName.ADD_OTP]: '/register/verify-otp',
  [TaskName.ADD_ADDRESS]: '/register/address',
  [TaskName.UPLOAD_ID]: '/register/upload-id',
  [TaskName.UPLOAD_SELFIE]: '/register/upload-selfie',
  [TaskName.ADD_PASSWORD]: '/register/set-password',
};

export const processToInitialTaskNameMap = {
  [ProcessId.REGISTER]: TaskName.REGISTER,
  [ProcessId.SOCIAL_REGISTER]: TaskName.REGISTER,         // #TODO
  [ProcessId.PROFILE_CONFIGURATION]: TaskName.REGISTER,   // #TODO
};

export const processToInitialUrlMap = {
  [ProcessId.REGISTER]: '/register',
  [ProcessId.SOCIAL_REGISTER]: '/#TODO',
  [ProcessId.PROFILE_CONFIGURATION]: '/#TODO',
};
