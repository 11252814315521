import React, { useState } from 'react';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { processService } from '../../../../processes/process.service';
import { TaskName } from '../../../../processes/process.types';
import { ProcessState } from '../../../../store/process.slice';
import { kycSliceActions, KycState } from '../../../../store/kyc.slice';
import Webcam from 'react-webcam';
import './register-page.step-selfie.scss';
import { useTranslation } from 'react-i18next';

const RegisterPageStepSelfie: React.FC = () => {
  const [img, setImg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const webcamRef = React.useRef<Webcam>(null);
  const processState = useSelector<AppStore, ProcessState>(store => store.process);
  const kycState = useSelector<AppStore, KycState>(store => store.kyc);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onImgConfirmHandler = () => {
    setIsComplete(true);
  }

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      await processService.completeTask<TaskName.UPLOAD_SELFIE>(processState, {
        selfieFile: {
          mime: '@file/jpeg',
          data: img as string
        }
      }, navigate, dispatch);

      // save the id card to the store
      dispatch(kycSliceActions.saveSelfie(img));

      await processService.startNextTask(processState.inProgress?.jobId, navigate, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const onSkipHandler = () => {
    // #TODO how to skip?
  }

  const captureImg = () => {
    const imageBase64 = webcamRef.current?.getScreenshot();
    setImg(imageBase64 || null);
  };

  const replaceImg = () => {
    setImg(null);
    setIsComplete(false);
  }

  return (
    <div className="register-page-step-selfie">
      <AppFormSectionTitle
        title={t('pages.register.step.selfie.form.title')}
        info={t('pages.register.step.selfie.form.info')}
      ></AppFormSectionTitle>

      <div className="form-element-width flex-column flex-align-start-center flex-gap-5">
        {!isComplete && !img && (
          <>
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              width={200}
              videoConstraints={{aspectRatio: (200/300)}}
            />
            <button onClick={captureImg}>{t('pages.register.step.selfie.button.capture-photo')}</button>
          </>
        )}

        {!isComplete && img && (
          <>
            <img src={img} width={200} alt="identity card photo" />
            <button onClick={replaceImg}>{t('pages.register.step.selfie.button.replace-photo')}</button>
          </>
        )}

        {isComplete && (
          <div className="flex-column flex-gap-35">
            {img && (
              <div className="document-container selfie-document-container">
                <div className="side-column"></div>
                <div className="center-column">
                  <img src={img} alt="selfie" />
                </div>
                <div className="side-column">
                  <span className="app-icon app-icon-check-circle"></span>
                </div>
              </div>
            )}
            {kycState.idCard && (
              <div className="document-container id-card-document-container">
                <div className="side-column"></div>
                <div className="center-column">
                  <img src={kycState.idCard} alt="id card" />
                </div>
                <div className="side-column">
                  <span className="app-icon app-icon-check-circle"></span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="form-buttons-container">
        <Button
          label={t('layout.buttons.next-step')}
          onClick={isComplete ? onSubmitHandler : onImgConfirmHandler}
          theme="accent"
          disabled={!img}
        ></Button>

        <Button
          label={t('Skip for now')}
          onClick={onSkipHandler}
          theme="outline"
        ></Button>
      </div>
    </div>
  );
};

export default RegisterPageStepSelfie;