import React from 'react';
import './button.scss';
import { ForwardedProps } from '../types/component-props.types';

interface ButtonProps extends ForwardedProps {
  theme?: 'accent' | 'outline' | 'outline-light';
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = (
  {theme = 'accent', label, disabled, onClick, className}
) => {
  return (
    <button
      className={`button ${theme} ${className || ''}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;