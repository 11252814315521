import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessId, TaskName } from '../processes/process.types';

export interface ProcessState {
  inProgress?: {
    processId: ProcessId;
    jobId: number;
    task?: {
      taskName: TaskName;
      humanTaskId: number;
    };
  };
}

const initialState: ProcessState = {};
const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    startProcess: (state: ProcessState, action: PayloadAction<{processId: ProcessId, jobId: number}>) => {
      state.inProgress = action.payload;
    },
    startTask: (state: ProcessState, action: PayloadAction<{taskName: TaskName, humanTaskId: number}>) => {
      if (state.inProgress) {
        state.inProgress.task = action.payload;
      }
    },
    completeTask: (state: ProcessState) => {
      if (state.inProgress) {
        state.inProgress.task = undefined;
      }
    },
    endProcess: (state: ProcessState) => {
      state.inProgress = undefined;
    },
  }
});

export const processReducer = processSlice.reducer;
export const processSliceActions = {
  startProcess: processSlice.actions.startProcess,
  startTask: processSlice.actions.startTask,
  completeTask: processSlice.actions.completeTask,
  endProcess: processSlice.actions.endProcess,
};