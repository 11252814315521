import React, { useState, useEffect } from 'react';
import "./custom-design-page.part.info-panel.scss";
import { useTranslation } from 'react-i18next';

type Tab = 'description' | 'redeem' | 'terms';

const InfoPanel: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>('description');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 'description':
        return (
          <>
            <p className='title'>{t('pages.custom-design.part.info-panel.description.sub-title1')}</p>
            <p className='text'>{t('pages.custom-design.part.info-panel.description.text1')}</p>
            <p className='title'>{t('pages.custom-design.part.info-panel.description.sub-title2')}</p>
            <p className='text'>{t('pages.custom-design.part.info-panel.description.text2')}</p>
            <p className='title'>{t('pages.custom-design.part.info-panel.description.sub-title3')}</p>
            <p className='text'> {t('pages.custom-design.part.info-panel.description.text3')}</p>
            <p className='text'> {t('pages.custom-design.part.info-panel.description.text4')}</p>
            <p> {t('pages.custom-design.part.info-panel.description.text5')}</p>
          </>
        );
      case 'redeem':
        return <div>{t('pages.custom-design.part.info-panel.redeem.text')}</div>;
      case 'terms':
        return <div>{t('pages.custom-design.part.info-panel.terms.text')}</div>;
      default:
        return null;
    }
  };

  return (
    <div className='custom-design-info-panel-container'>
      <div className="custom-design-info-panel">
        <div className="info-panel-tabs">
          <div className={`button-container ${activeTab === 'description' ? 'active' : ''}`}>
            <button onClick={() => setActiveTab('description')}>
              {t('pages.custom-design.part.info-panel.button.description')}
            </button>
            {screenWidth < 1080 && activeTab === 'description' && (
              <div className="content">{renderContent()}</div>
            )}
          </div>
          <div className={`button-container ${activeTab === 'redeem' ? 'active' : ''}`}>
            <button onClick={() => setActiveTab('redeem')}>
              {t('pages.custom-design.part.info-panel.button.redeem')}
            </button>
            {screenWidth < 1080 && activeTab === 'redeem' && (
              <div className="content">{renderContent()}</div>
            )}
          </div>
          <div className={`button-container ${activeTab === 'terms' ? 'active' : ''}`}>
            <button onClick={() => setActiveTab('terms')}>
              {t('pages.custom-design.part.info-panel.button.terms')}
            </button>
            {screenWidth < 1080 && activeTab === 'terms' && (
              <div className="content">{renderContent()}</div>
            )}
          </div>
        </div>
        {screenWidth >= 1080 && (
          <div className="content">{renderContent()}</div>
        )}
      </div>
    </div>
  );
};

export default InfoPanel;
