import { Navigate, RouteObject, RouterProvider } from "react-router-dom";


export const assistanceRouter: RouteObject[] = [
       {
        path: '',
        element: <Navigate to="how-it-works" replace />
       },
      {
        path: 'how-it-works',
      },
      {
        path: 'terms-and-conditions',
      },
      {
        path: 'faq',
      },
      {
        path: 'privacy-policy',
      },
      {
        path: 'about-us'
      }
]