import './page-switch-toggle.scss';
import "./switch-toggle.scss";

interface SwitchToggleProps<T extends string = string> {
  elements: {
    type: T;
    name: string;
  }[];
  activeElement: T;
  onChange: (value: T) => void;
}

const SwitchToggle = <T extends string>({ elements, activeElement, onChange }: SwitchToggleProps<T>) => {

  return (
    <div className="switch-toggle">
      {elements.map(element => (
        element.type === activeElement ? (
          <div
            key={element.type}
            className="switch-toggle-button active-button"
          >
            <span className="app-icon app-icon-check"></span>
            <span className="text">{element.name}</span>
          </div>
        ) : (
          <div
            key={element.type}
            className="switch-toggle-button"
            onClick={() => onChange(element.type)}
          >
            <span className="text">{element.name}</span>
          </div>
        )
      ))}
    </div>
  );
};

export default SwitchToggle;
