export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

enum LoggerType {
  DEFAULT = 'DEFAULT',      // by default, only error logs are sent to BE
  WITH_BE = 'WITH_BE',      // all logs are sent to BE
  SKIP_BE = 'SKIP_BE',      // no logs are sent to BE
}

class AppLogger {
  constructor(
    private readonly loggerDefault = new LoggerService(LoggerType.DEFAULT),
    private readonly loggerWithBE = new LoggerService(LoggerType.WITH_BE),
    private readonly loggerSkipBE = new LoggerService(LoggerType.SKIP_BE),
  ) {
  }

  withBE() {
    return this.loggerWithBE;
  }

  skipBE() {
    return this.loggerSkipBE;
  }

  debug(...args: any[]) {
    this.loggerDefault.debug(...args);
  }

  info(...args: any[]) {
    this.loggerDefault.info(...args);
  }

  warn(...args: any[]) {
    this.loggerDefault.warn(...args);
  }

  error(...args: any[]) {
    this.loggerDefault.error(...args);
  }
}

class LoggerService {
  constructor(
    private readonly loggerType: LoggerType
  ) {
  }

  debug(...args: any[]) {
    this.log('debug', args);
  }

  info(...args: any[]) {
    this.log('info', args);
  }

  warn(...args: any[]) {
    this.log('warn', args);
  }

  error(...args: any[]) {
    this.log('error', args);
  }

  private log(level: LogLevel, args: any[]) {
    const logText = this._argsToString(args);

    // log in FE
    console[level](logText);

    // log in BE ?
    if (
      this.loggerType === LoggerType.WITH_BE ||
      (level === 'error' && this.loggerType === LoggerType.DEFAULT)
    ) {
      // #TODO log in BE
    }
  }

  _argsToString(args: any[]) {
    return args
      .map(a => this._serialize(a))
      .join('\r\n\t');
  }

  _serialize(arg: any) {
    if (!arg) {
      return String(arg);
    } else if (arg instanceof Error) {
      // const httpError = arg;
      // return JSON.stringify({
      //   name: arg.name,
      //   message: arg.message,
      //   response: httpError.response ? {
      //     status: httpError.response.status,
      //     data: httpError.response.data
      //   } : undefined,
      //   stack: arg.stack
      // });
      return String(arg);
    } else if (typeof arg === 'object') {
      return JSON.stringify(arg);
    } else {
      return arg.toString();
    }
  }
}

const appLogger = new AppLogger();
export { appLogger };