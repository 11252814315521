import React from 'react';
import CenteredSection from '../../../../components/centered-section';
import './home-page.scss';
import { CardPurchaseType, GiftCardPurchaseModel, GiftCardTheme } from '../../../../models';
import GiftCardPurchase from '../../../../components/gift-card-purchase';
import SubtitleBicolor from '../../../../components/subtitle-bicolor';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import HomePagePartCustomCardSteps from './home-page.part.custom-card-steps';
import HomePagePartGiveAGift from './home-page.part.give-a-gift';
import HomePagePartInviteFriends from './home-page.part.invite-friends';
import HomePagePartPreferredBrands from './home-page.part.preferred-brand.';
import AppCheckboxInput from '../../../../components/form/base/app-checkbox-input';
import AppInput from '../../../../components/form/base/app-input';
import { useForm } from 'react-hook-form';
import AppFormInput from '../../../../components/form/app-form-input';
import { useNavigate } from 'react-router-dom';
import { customDesignSliceActions } from '../../../../store/custom-design.slice';
import { useDispatch } from 'react-redux';

const maleCardBgImg = require('../../../../assets/images/card-bg/male-card-bg.png');
const womanCardBgImg = require('../../../../assets/images/card-bg/card-woman.png');

const darkWomanCardBgImg = require('../../../../assets/images/card-bg/card-dark-woman.png');

const bossIconImg = require('../../../../assets/images/boss-icon.png');

const cardTypesBgImage = require('../../../../assets/images/landing/card-types-section-bg.png');
const videoBgImage = require('../../../../assets/images/landing/video-bg.png');

const shopByOccasionImages = {
  anytime: require('../../../../assets/images/landing/shop-by-occasion-anytime.png'),
  birthday: require('../../../../assets/images/landing/shop-by-occasion-birthday.png'),
  thank_you: require('../../../../assets/images/landing/shop-by-occasion-thank-you.png'),
  congrats: require('../../../../assets/images/landing/shop-by-occasion-congrats.png'),
  holiday: require('../../../../assets/images/landing/shop-by-occasion-holiday.png'),
  wedding: require('../../../../assets/images/landing/shop-by-occasion-wedding.png'),
};


interface GetUpdatesForm {
  checkbox: boolean;
  email: string;
}


const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm<GetUpdatesForm>({
    mode: 'onTouched',
    defaultValues: {

    }
  })

  const cardsForCardTypes: GiftCardPurchaseModel[] = [{
    backgroundImage: maleCardBgImg,
    title: t('pages.home.card-types-section.card-1.title'),
    text: 'A Gift For You',
    type: CardPurchaseType.PREPAID
  }, {
    iconImage: bossIconImg,
    backgroundColor: '#304123',
    title: t('pages.home.card-types-section.card-2.title'), priceRange: '50.000 - 500.000 FCFA',
    text: 'A Gift For You',
    type: CardPurchaseType.GIFT
  }, {
    backgroundImage: womanCardBgImg,
    title: t('pages.home.card-types-section.card-3.title'), priceRange: '50.000 - 500.000 FCFA',
    text: 'A Gift For You',
    type: CardPurchaseType.GIFT
  }];


  const shopByOccasionItems: (keyof typeof shopByOccasionImages)[] = [
    'anytime', 'birthday', 'thank_you', 'congrats', 'holiday', 'wedding'
  ];


  const popularCards: GiftCardPurchaseModel[] = [{
    backgroundImage: maleCardBgImg,
    title: t('pages.home.card-types-section.card-1.title'),
    text: 'A Gift For You',
    type: CardPurchaseType.PREPAID
  }, {
    iconImage: bossIconImg,
    backgroundColor: '#304123',
    title: t('pages.home.card-types-section.card-2.title'), priceRange: '50.000 - 500.000 FCFA',
    text: 'A Gift For You',
    type: CardPurchaseType.GIFT
  }, {
    backgroundImage: womanCardBgImg,
    title: t('pages.home.card-types-section.card-3.title'), priceRange: '50.000 - 500.000 FCFA',
    text: 'A Gift For You',
    type: CardPurchaseType.GIFT
  },
  {
    backgroundImage: darkWomanCardBgImg,
    title: t('pages.home.card-types-section.card-1.title'),
    text: 'A Gift For You',
    type: CardPurchaseType.PREPAID
  }
  ];

  const handleSelectCard = (card: GiftCardPurchaseModel) => {
    dispatch(customDesignSliceActions.saveTemplateDetails(card));
    if (card.iconImage == bossIconImg) {
      dispatch(customDesignSliceActions.saveCardValueDetails({ merchant: 'boss' }));
    } else {
      dispatch(customDesignSliceActions.saveCardValueDetails({}));
    }
    navigate('/custom-design/card-value', { state: { cardType: card.type } });
  }

  return (
    <div className="home-page">
      <CenteredSection className="card-types-section section-spacing">
        {/* <div className='background-image-left'>
          <img src={cardTypesBgImage} />
        </div>
        <div className='background-image-right'>
          <img src={cardTypesBgImage} />
        </div> */}
        <div className="cards-container-3-per-row">
          <div className="card-type">
            <GiftCardPurchase card={cardsForCardTypes[0]} onSelect={handleSelectCard}/>
            <div className='card-description-container'>
              <div className="separator"></div>
              <span className='card-description'>
                {t('pages.home.card-types-section.card-1.description')}
              </span>
            </div>
          </div>
          <div className="card-type">
            <GiftCardPurchase card={cardsForCardTypes[1]} onSelect={handleSelectCard}/>
            <div className='card-description-container'>
              <div className="separator"></div>
              <span className='card-description'>
                {t('pages.home.card-types-section.card-2.description')}
              </span>
            </div>
          </div>
          <div className="card-type">
            <GiftCardPurchase card={cardsForCardTypes[2]} onSelect={handleSelectCard}/>
            <div className='card-description-container'>
              <div className="separator"></div>
              <span className='card-description'>
                {t('pages.home.card-types-section.card-3.description')}
              </span>
            </div>
          </div>
        </div>
      </CenteredSection>

      <CenteredSection className="shop-by-occasion-section section-spacing-small flex-column flex-gap-30">
        <div className="flex-row flex-align-center-center">
          <SubtitleBicolor
            leftText={t('pages.home.shop-by-occasion-section.shop-by')}
            rightText={t('pages.home.shop-by-occasion-section.occasion')}
            isHeader={true}
            lightTheme={true}
          />
        </div>
        <div className="flex-row flex-wrap flex-align-center-center flex-gap-30">
          {shopByOccasionItems.map(item => (
            <div className="item-box" key={item}>
              <span>{/*placeholder*/}</span>
              <img src={shopByOccasionImages[item]} alt={item} />
              <div className='box-layer-container'>
                <span>{t(`pages.home.shop-by-occasion-section.item.${item}`)}</span>
              </div>
            </div>
          ))}
        </div>
      </CenteredSection>

      <div className="gradient-background">
        <CenteredSection className="section-spacing flex-column flex-gap-50">
          <div className="preferred-brand-section-header split-section-header">
            <h2 className='text-1 hide-gt-md'>Select your</h2>
            <h2 className='text-2 hide-gt-md'>preferred brand</h2>
            <div className='hide-md'>
              <SubtitleBicolor
                leftText="Select your"
                rightText="preferred brand"
                isHeader={true}
              />
            </div>
          </div>
          <HomePagePartPreferredBrands />
        </CenteredSection>
        <div>
          <CenteredSection className="custom-card-steps-section">
            <HomePagePartCustomCardSteps />
          </CenteredSection>

          <CenteredSection className="give-a-gift-section section-spacing">
            <HomePagePartGiveAGift />
          </CenteredSection>
        </div>
      </div>

      <CenteredSection>
        <HomePagePartInviteFriends />
      </CenteredSection>

      <CenteredSection className="popular-cards-section section-spacing flex-column flex-gap-30">
        <div className="split-section-header">
          <SubtitleBicolor
            leftText={t('pages.home.popular-cards-section.shop-popular')}
            rightText={t('pages.home.popular-cards-section.gift-cards')}
            isHeader={true}
          />

          <Button onClick={() => navigate('/e-gift-cards')} className="hide-md" label={t('layout.buttons.see-all-cards')} theme="outline" />
        </div>

        <div className="cards-container-4-per-row">
          <div className='icon-container hide-md'>
            <span className='icon-text'>{" < "}</span>
          </div>
          <GiftCardPurchase card={popularCards[0]} useIconButton={true} onSelect={handleSelectCard}/>
          <GiftCardPurchase card={popularCards[1]} useIconButton={true} onSelect={handleSelectCard}/>
          <GiftCardPurchase card={popularCards[2]} useIconButton={true} onSelect={handleSelectCard}/>
          <GiftCardPurchase card={popularCards[3]} useIconButton={true} onSelect={handleSelectCard}/>
          <div className='icon-container hide-md'>
            <span className='icon-text'>{" > "}</span>
          </div>
        </div>

        <div className="bottom-button flex-row flex-align-center-center hide-gt-md">
          <Button onClick={() => navigate('/e-gift-cards')} label={t('layout.buttons.see-all-cards')} theme="outline" />
        </div>
      </CenteredSection>

      <div className='video-container'>
        <img src={videoBgImage} />
        <div className='video'></div>
        <span className='text'>Watch our latest promo video now!</span>
      </div>

      <CenteredSection className="get-updates-section section-spacing flex-column flex-gap-30">
        <div className="flex-row flex-align-center-center">
          <h2 className='title'>Get Updates for the Gift Card Shop</h2>
        </div>
        <div className='get-updates-container'>
          <AppCheckboxInput
            id="name"
            label="Kindly send me updates, news, and exclusive offers from Bloom and its affiliates. I acknowledge that my information will be handled according to the terms outlined here and in the  Privacy Policy."
            checked={true}
            onChange={() => { }}
          />
          <AppFormInput
            control={form.control}
            name='email'
            label=''
            placeholder='Enter your email address'
          />
          <Button label='Sign me Up!'></Button>
        </div>
      </CenteredSection>
    </div>
  );
};

export default HomePage;

function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
