import { configureStore } from '@reduxjs/toolkit';
import { processReducer, ProcessState } from './process.slice';
import { kycReducer, KycState } from './kyc.slice';
import { registerReducer, RegisterState } from './register.slice';
import { userReducer, UserState } from './user.slice';
import { navigationReducer, NavigationState } from './navigation.slice';
import { customDesignReducer, CustomDesignState } from './custom-design.slice';
import { shoppingCartReducer, ShoppingCartState } from './shopping-card.slice';

export interface AppStore {
  process: ProcessState;
  register: RegisterState;
  kyc: KycState;
  user: UserState;
  navigation: NavigationState;
  customDesign: CustomDesignState;
  shoppingCart: ShoppingCartState;
}

export const store = configureStore({
  reducer: {
    process: processReducer,
    register: registerReducer,
    kyc: kycReducer,
    user: userReducer,
    navigation: navigationReducer,
    customDesign: customDesignReducer,
    shoppingCart: shoppingCartReducer
  }
});
