import React, { HTMLInputTypeAttribute, useRef } from 'react';
import './app-input.scss';
import { ForwardedProps } from '../../../types/component-props.types';
import Button from '../../button';

interface AppFormInputProps extends ForwardedProps {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onBlur?: () => void;
  onChange?: (e?: any) => void;
  value?: any;
  readOnly?: boolean;
}

const AppInput: React.FC<AppFormInputProps> = (
  {type = 'text', value, placeholder, onBlur, onChange, id, className, readOnly }
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  
  const focusInput = () => {
    inputRef.current?.focus();
  }

  return (
    <div className={`app-input form-field-container ${className || ''}`} onClick={focusInput}>
      <input
        ref={inputRef}
        id={id}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
      {/* { onSubmit && <Button label='Sign me up' />} */}
    </div>
  );
  
}

export default AppInput;
