import React from 'react';
import './app-form-readonly.scss';

interface AppFormReadonlyProps {
  label: string;
  value?: string | number | null;
}

const AppFormReadonly: React.FC<AppFormReadonlyProps> = (
  {label, value}
) => {
  return (
    <div className="app-form-readonly">
      <span className="label">{label}</span>
      <span className="value">{value}</span>
    </div>
  );
}

export default AppFormReadonly;
