import { t } from "i18next"
import PageTitle from "../../../../components/page-title"
import { Outlet, useLocation } from "react-router-dom"
import InfoPanel from "./custom-design-page.part.info-panel"
import "./custom-design-page.scss"
import { useTranslation } from "react-i18next"

const CustomDesignPage: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const cardType = (location.state?.cardType as string | undefined)?.toLowerCase();
    console.log(cardType);
    
    return (
        <div className="page-outer">
            <div className="page-content">
                <PageTitle text={t(`pages.custom-design.title.${ cardType || 'custom' }`)}></PageTitle>
                <div className="custom-design-page-content-container" >
                    <Outlet/>
                </div>
                <InfoPanel/>
            </div>
        </div>)
}

export default CustomDesignPage