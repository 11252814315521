import React from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import './app-form-counter-input.scss';
import AppInput from './base/app-input';

interface AppFormCounterInputProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  label?: string;
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
  className?: string;
  onChange?: (value: number) => void;
}

const AppFormCounterInput = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  label,
  min = 1,
  max = 99,
  step = 1,
  className,
  onChange
}: AppFormCounterInputProps<TForm, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={`app-form-counter-input ${className || ''}`}>
          <label htmlFor={name} className="counter-input-label">
            {label}
          </label>
          <div className="counter-input-controls">
            <button
              type="button"
              className="counter-input-button decrease"
              onClick={() => {
                const newValue = Math.max(field.value - step, min);
                field.onChange(newValue);
                if (onChange)
                  onChange(newValue);
              }
              }
            >
              -
            </button>
            <div className="counter-input-container">
              <AppInput
                id={name}
                type="number"
                value={field.value}
                onChange={(e) => {
                  const newValue = +e.target.value;
                  field.onChange(newValue);
                  if (onChange) onChange(newValue);
                }
                }
                className="counter-input"
              />
            </div>
            <button
              type="button"
              className="counter-input-button increase"
              onClick={() => {
                const newValue = Math.min(field.value + step, max);
                field.onChange(newValue);
                if (onChange) onChange(newValue);
              }
              }
            >
              +
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default AppFormCounterInput;
