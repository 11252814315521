import React from 'react';
import './page-switch-toggle.scss';
import { useNavigate } from 'react-router-dom';

interface PageSwitchToggleProps<T extends string = string> {
  pages: {
    page: T;
    name: string;
    url: string;
  }[];
  activePage: T;
}

const PageSwitchToggle: React.FC<PageSwitchToggleProps> = ({pages, activePage}) => {
  const navigate = useNavigate();

  return (
    <div className="page-switch-toggle">
      {pages.map(page => (
        page.page === activePage ? (
          <div
            key={page.page}
            className="switch-button active-button"
          >
            <span className="app-icon app-icon-check"></span>
            <span className="text">{page.name}</span>
          </div>
        ) : (
          <div
            key={page.page}
            className="switch-button"
            onClick={() => navigate(page.url)}
          >
            <span className="text">{page.name}</span>
          </div>
        )
      ))}
    </div>
  );
};

export default PageSwitchToggle;
