import React from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import './app-form-color-selector.scss';

export interface SelectColorType {
  color: string;
  value: string;
}

interface AppFormSquareGroupProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  options: SelectColorType[];
}

const AppFormColorSelector = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  options,
}: AppFormSquareGroupProps<TForm, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="app-form-color-selector-container">
          {options.map((option, id) => (
            <div
              key={id}
              className={`color-option ${field.value?.value === option.value ? 'selected' : ''}`}
              style={{ backgroundColor: option.color }}
              onClick={() => field.onChange(option)}
            />
          ))}
        </div>
      )}
    />
  );
};

export default AppFormColorSelector;
