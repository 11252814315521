import React, { PropsWithChildren } from 'react';
import './dashboard-section-container.scss';

interface DashboardSectionContainerProps extends PropsWithChildren {
}

const DashboardSectionContainer: React.FC<DashboardSectionContainerProps> = (
  {children}
) => {
  return (
    <div className="dashboard-section-container">
      {children}
    </div>
  );
};

export default DashboardSectionContainer;