import React from 'react';
import { Outlet } from 'react-router-dom';
import PageTitle from '../../../../components/page-title';
import PageSwitchToggle from '../../../../components/page-switch-toggle';
import ContentBox from '../../../../components/content-box';
import { useTranslation } from 'react-i18next';

enum AuthPage {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER'
}

const RegisterPage: React.FC = () => {
  const {t} = useTranslation();

  const pages = [{
    page: AuthPage.LOGIN,
    name: t('pages.login.tabs.login'),
    url: '/login'
  }, {
    page: AuthPage.REGISTER,
    name: t('pages.login.tabs.register'),
    url: '/register'
  }];

  return (
    <div className="page-outer">
      <div className="page-content">
        <PageTitle text={t('pages.register.title')}></PageTitle>

        <PageSwitchToggle pages={pages} activePage={AuthPage.REGISTER}></PageSwitchToggle>

        <ContentBox>
          <Outlet />
        </ContentBox>
      </div>
    </div>
  );
};

export default RegisterPage;