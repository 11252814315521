import React from 'react';
import './line-separator.scss';
import { ForwardedProps } from '../types/component-props.types';

interface LineSeparatorProps extends ForwardedProps{

}

const LineSeparator: React.FC<LineSeparatorProps> = ({className}) => {
  return (
    <div className={`line-separator ${className || ''}`}></div>
  );
};

export default LineSeparator;