import React from 'react';
import './subtitle-bicolor.scss';

interface SubtitleBicolorProps {
  leftText: string;
  rightText: string;
  isHeader?: boolean;
  lightTheme?: boolean;
}

const SubtitleBicolor: React.FC<SubtitleBicolorProps> = (
  {leftText, rightText, isHeader, lightTheme}
) => {
  return isHeader ? (
    <h2 className={`subtitle-bicolor is-header ${lightTheme ? 'light-theme' : ''}`}>
      {leftText}&nbsp;<span className={`text-bolded is-header ${lightTheme ? 'light-theme' : ''}`}>{rightText}</span>
    </h2>
  ) : (
    <div className={`subtitle-bicolor ${lightTheme || ''}`}>
      {leftText}&nbsp;<span className="text-bolded">{rightText}</span>
    </div>
  );
};

export default SubtitleBicolor;
