import './gift-card.scss';
import { useTranslation } from 'react-i18next';
import { dateService } from '../services/date.service';
import React, { useEffect, useState } from 'react';
import { GiftCardModel } from '../models';
import { UseFormSetValue } from 'react-hook-form';
import { SwitchToggleElements } from '../router/public/pages/create-custom-design/custom-design.step-customize';

const logoDark = require('../assets/images/bloom-logo.png');
const logoLight = require('../assets/images/bloom-logo-full-white.png');
const masterCardLogo = require('../assets/images/mastercard-logo.png');
const qrCodePlaceholder = require('../assets/images/qr-code-placeholder.png');
const cipImage = require('../assets/images/cip.png');

const bloomLogoAccent = require('../assets/images/bloom-logo-red.png');


interface GiftCardProps {
  card: GiftCardModel;
  customizeType?: string;
  setValue?: UseFormSetValue<any>;
}

const GiftCard: React.FC<GiftCardProps> = (
  { card, customizeType, setValue }
) => {
  const { t } = useTranslation();
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>(card.backgroundImage);

  useEffect(() => {
    setBackgroundImage(card.backgroundImage);
  }, [card.backgroundImage]);

 

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };


  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (customizeType == SwitchToggleElements.SELECT) {
      return;
    }
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/') && setValue) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > 300 && img.height > 200) {
          setBackgroundImage(img.src);
          setValue('uploadedImage', img.src);
        } 
      };
    }
  };

  const showDragText = customizeType == SwitchToggleElements.UPLOAD && !backgroundImage;

  return (
    <div 
        className={`gift-card theme-${card.theme} ${customizeType === SwitchToggleElements.UPLOAD ? 'upload-on' : ''}`} 
        style={{ backgroundColor: card.backgroundColor }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        >
          {card.backgroundImage &&
      <div className="background-img-container">
        <img src={card.backgroundImage} alt="gift card" />
      </div>
}
      {showDragText && (
        <div className="drag-text-container">
          <span className="drag-text">{t('Drag & Drop files here')}</span>
        </div>
      )}


      <div className="left-side card-layer-container">
        <img src={card.theme === 'light' ? logoDark : card.theme === 'accent' ? bloomLogoAccent : logoLight } alt="Bloom logo" />
        {!showDragText && <img src={cipImage} alt="Cip image" className='cip-image'/>}
        <img className='mastercard-logo-hidden' src={masterCardLogo} alt="MasterCard logo" />
      </div>

      <div className="right-side card-layer-container">
      <img className="bloom-logo-hidden" src={card.theme === 'light' ? logoDark : logoLight} alt="Bloom logo" />
        {!showDragText  && <img className='qr-image' src={qrCodePlaceholder} alt="QR image" />}
        <img src={masterCardLogo} alt="MasterCard logo" />
      </div>

      {card.text &&
        <div className="card-text card-layer-container">
          <span>{card.text}</span>
        </div>
      }

      {card.iconImage && (
        <div className="icon-container">
          <img src={card.iconImage} alt="Custom Icon" />
        </div>
      )}
    </div>
  );
};

export default GiftCard;
