import React, { ReactNode } from 'react';
import { Message } from 'react-hook-form/dist/types/errors';
import { Control, Controller, ControllerRenderProps, FieldValues, FieldPath } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import './app-form-radio-group.scss';
import AppFormElementContainer from './base/app-form-element-container';

interface Validations {
  required: Message | ValidationRule<boolean>;
}

export interface RadioOption<T extends (string | number)> {
  value: T;
  text?: string;
  secondaryText?: string;
  icon?: ReactNode;
}

interface AppFormRadioGroupProps<TForm extends FieldValues, TName extends FieldPath<TForm>, TValue extends (string | number)> {
  control: Control<TForm, TName>;
  name: TName;
  options: RadioOption<TValue>[];
  validations?: Partial<Validations>;
  onChange?: (value: TValue) => void;
}

const AppFormRadioGroup = <TForm extends FieldValues, TName extends FieldPath<TForm>, TValue extends (string | number)>(
  { control, name, options, validations, onChange,  }: AppFormRadioGroupProps<TForm, TName, TValue>
) => {

  const onSelect = (field: ControllerRenderProps<TForm, TName>, value: TValue) => {
    field.onChange(value);
    field.onBlur();

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <AppFormElementContainer className={`app-form-radio-group`}>
      <Controller
        name={name}
        control={control}
        rules={validations}
        render={({ field, fieldState }) => (
          <>
            <div className="radio-group-container">
              {(options.map(option => (
                <div
                  key={option.value}
                  onClick={() => onSelect(field, option.value)}
                  className={`radio-option-container`}
                >
                  <div
                    className={`radio-container ${(field.value === option.value ? 'checked' : '')}`}
                  >
                    <div className="radio-checked"></div>
                  </div>
                  {option.text &&
                  <div className={`radio-text-container `}>
                    <p className={`radio-text`}>{option.text}{option.secondaryText && <span className="radio-secondary-text">{` - ${option.secondaryText}`}</span>}</p>
                  </div>
                  }
                  {option.icon}
                </div>
              )))}
            </div>

            <p className="form-element-error">{fieldState.error?.type || ' '}</p>
          </>
        )}
      />
    </AppFormElementContainer>
  );
}

export default AppFormRadioGroup;
