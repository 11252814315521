import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './my-account-layout.scss';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/page-title';
import MyAccountMenu from './my-account-menu';
import { navigationSliceActions } from '../../store/navigation.slice';
import { useDispatch } from 'react-redux';

const MyAccountLayout: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // clear state on unmount
      dispatch(navigationSliceActions.removeAccountPage());
    };
  }, []);

  return (
    <div className="my-account-layout page-outer">
      <div className="page-content">
        <PageTitle text={t('my-account.layout.title')}></PageTitle>

        <div className="account-router">
          <div className="account-menu">
            <MyAccountMenu />
          </div>
          <div className="account-page">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccountLayout;