import { useForm, useWatch } from 'react-hook-form';
import './home-page.part.invite-friends.scss'
import { useTranslation } from 'react-i18next';
import AppFormInput from '../../../../components/form/app-form-input';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import Button from '../../../../components/button';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import { useState } from 'react';
import AppFormDropdown from '../../../../components/form/app-form-dropdown';
import { LabelValue } from '../../../../types/label-value.types';
import { apiService } from '../../../../services/api.service';

const inviteFriendsImg = require('../../../../assets/images/landing/invite-friends.png');




interface InviteFriendsFormValue {
    email: string;
    prefix: LabelValue;
    phoneNumber: string;
}

const prefixOptions = [
    {
        label: '+225',
        value: '+225'
    },
    {
        label: '+227',
        value: '+227'
    }
]

const HomePagePartInviteFriends: React.FC = () => {
    const userState = useSelector<AppStore, UserState>(store => store.user);
    const [link, setLink] = useState('');
    const { t } = useTranslation();

    const form = useForm<InviteFriendsFormValue>({
        mode: 'onTouched',
        defaultValues: {
            prefix: {
                label: '+225',
                value: '+225'
            }
        }
    });

    const emailForm = useWatch({
        control: form.control,
        name: 'email'
    })

    const phoneNumberForm = useWatch({
        control: form.control,
        name: 'phoneNumber'
    })

    const handleSubmit = async () => {
        if (phoneNumberForm) {
            try {

                await apiService.postWhatsAppInvite(phoneNumberForm);
            }
            catch (error) {
                console.log(error);

            } finally {
                form.reset();
                setLink('');
            }
        } if (emailForm) {
            await apiService.postEmailInvite(emailForm);
        } else {
            // await apiService.getClient().post('/');
            setLink('ihcbbq qiu6267389');
        }
    }

    return (<>{userState.user &&
        <div className='home-page-part-invite-friends-container'>
            <div className="home-page-part-invite-friends">
                <div>
                    <img src={inviteFriendsImg} />
                </div>
                <div className='form-container'>
                    <AppFormSectionTitle
                        title="Invite friends"
                        info="Invite your friends to use Bloom for personalized E-gift cards and Prepaid cards! Create something unique today!"
                    />
                    <div className='form-inputs-container'>
                        <div className='form-email'>
                            <AppFormInput
                                name="email"
                                control={form.control}
                                label={t('forms.field.email.label')}
                                validations={{ minLength: 5 }}
                                type="email"
                            />
                        </div>
                        <div className='form-phone-number-container'>
                            <div className='flex-column'>
                                <p className='phone-number-label'>WhatsApp Number:</p>
                                <div className='form-phone-number-container'>
                                    <div className='form-prefix'>
                                        <AppFormDropdown
                                            name="prefix"
                                            control={form.control}
                                            options={prefixOptions}
                                        />
                                    </div>
                                    <div className='form-phone-number'>
                                        <AppFormInput
                                            name="phoneNumber"
                                            control={form.control}
                                            label=" "
                                            placeholder='Phone'
                                            validations={{ minLength: 5 }}
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button label={emailForm || phoneNumberForm ? 'Send' : 'Generate Link'} onClick={handleSubmit} />

                    </div>
                    {link && <div className='link-container'>
                        <p className='link-text'>Your generated link: <span className='link'> {link}</span></p>
                        <p className='copy-share'>Copy</p>
                        <p className='copy-share'>Share</p>
                    </div>
                    }
                </div>
            </div>
        </div>
    }</>)
}

export default HomePagePartInviteFriends