import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GiftCardPurchaseModel } from '../models';
import { CustomCardValueFormValue } from '../router/public/pages/create-custom-design/custom-design.step-card-value';

export interface CustomDesignState {
    cardTemplate?: GiftCardPurchaseModel;
    cardValue?: Partial<CustomCardValueFormValue>;
}

const initialState: CustomDesignState = {};
const customDesignSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        saveTemplateDetails: (state: CustomDesignState, action: PayloadAction<GiftCardPurchaseModel>) => {
            state.cardTemplate = action.payload;
        },
        saveCardValueDetails: (state: CustomDesignState, action: PayloadAction<Partial<CustomCardValueFormValue>>) => {
            state.cardValue = action.payload;
        },
    }
});

export const customDesignReducer = customDesignSlice.reducer;
export const customDesignSliceActions = {
    saveTemplateDetails: customDesignSlice.actions.saveTemplateDetails,
    saveCardValueDetails: customDesignSlice.actions.saveCardValueDetails,
};