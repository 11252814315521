import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import Button from '../../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { registerSliceActions, RegisterState } from '../../../../store/register.slice';
import AppFormRadioGroup from '../../../../components/form/app-form-radio-group';
import { SendOtpThrough } from '../../../../services/api.service.types';
import AppFormInput from '../../../../components/form/app-form-input';
import { processService } from '../../../../processes/process.service';
import { ProcessId } from '../../../../processes/process.types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dateService } from '../../../../services/date.service';

export interface PersonalDetailsVerificationFormValue extends FieldValues {
  verifyVia: SendOtpThrough;
  whatsappNumber?: string;
  email?: string;
}

const RegisterPageStepVerification: React.FC = () => {
  const [verifyVia, setVerifyVia] = useState<SendOtpThrough>();
  const registerState = useSelector<AppStore, RegisterState>(store => store.register);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const form = useForm<PersonalDetailsVerificationFormValue>({
    mode: 'onTouched',
    defaultValues: {}
  });

  const onSubmitHandler = async (data: PersonalDetailsVerificationFormValue) => {
    
    const jobId = await processService.startProcess(
      ProcessId.REGISTER,
      {
        firstName: registerState.personalDetails!.firstName,
        lastName: registerState.personalDetails!.lastName,
        birthdate: dateService.formatBirthDate(registerState.personalDetails!.birthDate)!,
        sendOtpThrough: data.verifyVia,
        phone: data.phoneNumber || '+40722333444',     // #TODO fix API validation
        email: data.email || 'mihai@dummy.ro',            // #TODO fix API validation
        userName: data.email || 'mihai@dummy.ro',         // #TODO what if user uses WhatsApp number only?
      },
      dispatch
    );

    dispatch(registerSliceActions.saveVerification(data));

    void processService.startNextTask(jobId, navigate, dispatch);
  };

  
  return (
    <>
      <AppFormSectionTitle
        title={t('pages.register.step.verification.form.title')}
        info={t('pages.register.step.verification.form.info')}
        required={true}
      ></AppFormSectionTitle>

      <AppFormInput
        name="email"
        control={form.control}
        label={t('forms.field.email.label')}
        validations={{ required: true, minLength: 5 }}
        type="email"
      />

      <AppFormInput
        name="phoneNumber"
        control={form.control}
        label={t('forms.field.phone-number.label')}
        validations={{ required: verifyVia === SendOtpThrough.WHATSAPP ? true : false, minLength: 5 }}
        type="text"
      />

      <AppFormRadioGroup
        name="verifyVia"
        control={form.control}
        validations={{ required: true }}
        options={[{
          value: SendOtpThrough.WHATSAPP,
          text: t('pages.register.step.verification.form.field.verify-via.whatsapp'),
          icon: (
            <img
              style={{ width: 32 }}
              src={require('../../../../assets/images/whatsapp-logo.png')}
              alt="verify via Whatsapp"
            />
          )
        }, {
          value: SendOtpThrough.EMAIL,
          text: t('pages.register.step.verification.form.field.verify-via.email'),
        }]}
        onChange={setVerifyVia}
      />

      <div className="form-buttons-container">
        <Button
          label={t('pages.register.step.verification.button.send-code')}
          onClick={form.handleSubmit(onSubmitHandler)}
          theme="accent"
          disabled={!form.formState.isValid}
        ></Button>
      </div>
    </>
  );
};

export default RegisterPageStepVerification;