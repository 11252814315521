import React from 'react';
import { RouteObject } from 'react-router/dist/lib/context';
import MyProfilePage from './pages/my-profile/my-profile-page';

export const myAccountRouter: RouteObject[] = [
  {
    path: 'profile',
    element: <MyProfilePage />
  },
];
