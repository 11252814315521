import { useEffect, useState } from "react";
import PageTitle from "../../../../components/page-title";
import './assistance-page.scss'
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface TopicItem {
    title: string;
    content: string;
}

interface Topic {
    title: string
    items: TopicItem[];
}

const AssistancePage = () => {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const section = pathParts[pathParts.length - 1];
    const { t } = useTranslation();
    const topicAndItemsPerPage = [{
        path: 'how-it-works',
        itemsPerTopic: [
            3, 2, 3, 2, 2, 2, 1, 2
        ]
    }, {
        path: 'faq',
        itemsPerTopic: [
            2, 1, 4, 4, 1, 4, 2, 7, 2, 1, 1, 1, 1, 1, 1, 1, 3
        ]
    }, {
        path: 'privacy-policy',
        itemsPerTopic: [1, 6, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1]
    }, {
        path: 'terms-and-conditions',
        itemsPerTopic: [1, 2, 1, 1, 1, 1]
    }, {
        path: 'about-us',
        itemsPerTopic: [1, 1, 1, 1, 1, 1, 1]
    }
    ]
    const currentSection = topicAndItemsPerPage.find(sectionConfig => sectionConfig.path === section);

    const topics: Topic[] = currentSection ? currentSection.itemsPerTopic.map((itemsCount, topicIndex) => {
        const topicKey = `pages.assistance.${section}.topic-${topicIndex + 1}`;
        return {
            title: t(`${topicKey}`),
            items: Array.from({ length: itemsCount }, (_, itemIndex) => {
                const itemKey = `${topicKey}.item-${itemIndex + 1}`;
                return {
                    title: t(`${itemKey}.title`),
                    content: t(`${itemKey}.content`)
                };
            })
        };
    }) : [];
    const [activeTopic, setActiveTopic] = useState<Topic>(topics[0]);
    const [activeItem, setActiveItem] = useState<string>(activeTopic.items[0].title);

    useEffect(() => {
        if (activeTopic) {
            setActiveItem(activeTopic.items[0].title);
        }
    }, [activeTopic]);


    useEffect(() => {
        setActiveTopic(topics[0]);
    }, [location])

    return (
        <div className="page-outer">
            <div className="page-content">
                <PageTitle text={t(`pages.assistance.${section}.title`)}></PageTitle>
                <div className="assistance-page-container">
                    <div className="topic-menu">
                        {topics.map((topic, idx) => (
                            <div className={`topic-item ${topic.title == activeTopic.title ? 'active' : ''}`}
                                onClick={() => {
                                    setActiveTopic(topic)
                                }}
                            >
                                <h4>{topic.title}</h4>
                                <span className="app-icon app-icon-arrow-right"></span>
                            </div>
                        ))
                        }
                    </div>
                    <div className="topic-content">
                        {activeTopic && activeTopic.items.map((item, idx) => (<>
                            <div className="content-container">
                                <h4 className="content-title" onClick={() => { setActiveItem(item.title) }}><span>{activeItem == item.title ? ' - ' : ' + '}</span>{item.title}</h4>
                                {activeItem === item.title && <div className="content-text-container">
                                    <p className="content-text"> {item.content}
                                    </p>
                                </div>
                                }
                            </div>
                        </>
                        ))
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AssistancePage;