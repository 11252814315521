import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface KycState {
  idCard?: any;     // #TODO uploaded image type
  selfie?: any;
}

const initialState: KycState = {};
const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    // #TODO uploaded image type
    saveIdCard: (state: KycState, action: PayloadAction<any>) => {
      state.idCard = action.payload;
    },
    // #TODO uploaded image type
    saveSelfie: (state: KycState, action: PayloadAction<any>) => {
      state.selfie = action.payload;
    },
  }
});

export const kycReducer = kycSlice.reducer;
export const kycSliceActions = {
  saveIdCard: kycSlice.actions.saveIdCard,
  saveSelfie: kycSlice.actions.saveSelfie,
};