import React, { useEffect } from 'react';
import DashboardPageTitle from '../../../../components/dashboard-page-title';
import DashboardSectionContainer from '../../../../components/dashboard-section-container';
import DashboardFormSection from '../../../../components/dashboard-form-section';
import { useDispatch, useSelector } from 'react-redux';
import { navigationSliceActions } from '../../../../store/navigation.slice';
import { MyAccountPage } from '../../my-account-menu';
import AppFormReadonly from '../../../../components/form/app-form-readonly';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import CardDetailsReadonly from '../../../../components/card-details-readonly';
import LineSeparator from '../../../../components/line-separator';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountAddressFormValue, AccountNameFormValue } from './my-profile-page.types';
import AppFormInput from '../../../../components/form/app-form-input';
import AppFormDatePicker from '../../../../components/form/app-form-date-picker';
import { AddressModelClass } from '../../../../models';

const MyProfilePage: React.FC = () => {
  const dispatch = useDispatch();
  const userState = useSelector<AppStore, UserState>(store => store.user);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(navigationSliceActions.setAccountPage(MyAccountPage.MY_PROFILE));
  }, []);

  const accountNameForm = useForm<AccountNameFormValue>({
    mode: 'onTouched',
    defaultValues: userState.user || {}
  });
  const deliveryAddressForm = useForm<AccountAddressFormValue>({
    mode: 'onTouched',
    defaultValues: userState.user?.address || {}
  });

  const onAccountNameSubmitHandler = (data: AccountNameFormValue) => {
    console.log('Saving account name: ', data);
  };
  const onDeliveryAddressSubmitHandler = (data: AccountAddressFormValue) => {
    console.log('Saving delivery address: ', data);
  };

  return (
    <div className="my-profile-page">
      <DashboardPageTitle text={t('pages.my-profile.title')}></DashboardPageTitle>

      <div className="dashboard-page-content flex-wrap">
        <DashboardSectionContainer>
          <DashboardFormSection
            title={t('pages.my-profile.section.account-name.title')}
            onSave={accountNameForm.handleSubmit(onAccountNameSubmitHandler)}
            formIsValid={accountNameForm.formState.isValid}
            readonlyView={
              <div className="flex-column flex-gap-25">
                <AppFormReadonly label={t('forms.field.first-name.label')} value={userState.user?.firstName} />
                <AppFormReadonly label={t('forms.field.last-name.label')} value={userState.user?.lastName} />
                <AppFormReadonly label={t('forms.field.phone-number.label')} value={userState.user?.phone} />
                <AppFormReadonly label={t('forms.field.email.label')} value={userState.user?.email} />
              </div>
            }
            editView={
              <div className="flex-column">
                <AppFormInput
                  name="firstName"
                  control={accountNameForm.control}
                  label={t('forms.field.first-name.label')}
                  validations={{ required: true }}
                />

                <AppFormInput
                  name="lastName"
                  control={accountNameForm.control}
                  label={t('forms.field.last-name.label')}
                  validations={{ required: true }}
                />

                <AppFormInput
                  name="phoneNumber"
                  control={accountNameForm.control}
                  label={t('forms.field.phone-number.label')}
                  validations={{ required: true, minLength: 5 }}
                  type="text"
                />

                <AppFormInput
                  name="email"
                  control={accountNameForm.control}
                  label={t('forms.field.email.label')}
                  validations={{ required: true, minLength: 5 }}
                  type="email"
                />

                <AppFormDatePicker
                  name="birthDate"
                  control={accountNameForm.control}
                  label={t('forms.field.birth-date.label')}
                  validations={{ required: true }}
                />
              </div>
            }
          />

          <DashboardFormSection
            title="Cards"
            onSave={() => Promise.resolve()}
            readonlyView={
              <div className="flex-column flex-gap-25">
                {userState.user?.bankCards?.map((card, idx) => (
                  <React.Fragment key={idx}>
                    <CardDetailsReadonly card={card} />
                    {idx < (userState.user?.bankCards?.length! - 1) && (
                      <LineSeparator />
                    )}
                  </React.Fragment>
                ))}
              </div>
            }
            editView={
              <>
                <span>Edit Mode </span>
              </>
            }
          />
        </DashboardSectionContainer>

        <DashboardSectionContainer>
          <DashboardFormSection
            title={t('pages.my-profile.section.addresses.title')}
            onSave={deliveryAddressForm.handleSubmit(onDeliveryAddressSubmitHandler)}
            formIsValid={deliveryAddressForm.formState.isValid}
            readonlyView={
              <div className="flex-column flex-gap-50">
                {userState.user?.address && (
                  <AppFormReadonly label={t('forms.field.delivery-address.label')} value={AddressModelClass.getAddressString(userState.user.address)} />
                )}
                {userState.user?.billingAddress && (
                  <AppFormReadonly label={t('forms.field.billing-address.label')} value={AddressModelClass.getAddressString(userState.user.billingAddress)} />
                )}
              </div>
            }
            editView={
              <div className="flex-column">
                <AppFormInput
                  name="addressLine1"
                  control={deliveryAddressForm.control}
                  label={t('forms.field.address-line-1.label')}
                  validations={{ required: true }}
                />
                <AppFormInput
                  name="addressLine2"
                  control={deliveryAddressForm.control}
                  label={t('forms.field.address-line-2.label')}
                  validations={{ required: true }}
                />
                <AppFormInput
                  name="city"
                  control={deliveryAddressForm.control}
                  label={t('forms.field.city.label')}
                  validations={{ required: true }}
                />
                <AppFormInput
                  name="country"
                  control={deliveryAddressForm.control}
                  label={t('forms.field.country.label')}
                  validations={{ required: true }}
                />
                <AppFormInput
                  name="postalCode"
                  control={deliveryAddressForm.control}
                  label={t('forms.field.postal-code.label')}
                  validations={{ required: true }}
                />
              </div>
            }
          />

          <DashboardFormSection
            title="Company"
            onSave={() => Promise.resolve()}
            readonlyView={
              <div className="flex-column flex-gap-30">
                <AppFormReadonly label="Company Name" value={userState.user?.companyName} />
                <AppFormReadonly label="CRN" value="TBD" />
                {userState.user?.address && (
                  <AppFormReadonly
                    label={t('forms.field.address.label')}
                    value={AddressModelClass.getAddressString(userState.user.address)}
                  />
                )}
              </div>
            }
            editView={
              <>
                <span>Edit Mode </span>
              </>
            }
          />
        </DashboardSectionContainer>
      </div>
    </div>
  );
};

export default MyProfilePage;