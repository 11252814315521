
export enum StorageKey {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken'
}

class LocalStorageService {
   getItem<T = string>(key: StorageKey, isObject: boolean = false): T | null {
    const value =  localStorage.getItem(key);
    return (value === null || value === 'null') ?
      null :
      (
        isObject ? JSON.parse(value) : value
      ) as unknown as T;
  }

   setItem(key: StorageKey, value: string, isObject: boolean = false) {
    const valueToSet = isObject ? JSON.stringify(value) : value;
     localStorage.setItem(key, valueToSet);
  }

   removeItem(key: StorageKey) {
     localStorage.removeItem(key);
  }
}

const localStorageService = new LocalStorageService();
export { localStorageService };