import { LoginResponse } from "./auth.service-types";
import { jwtDecode } from "jwt-decode";
import { LoginJwtPayload } from "./key-cloak-api.service.types";
import { keyCloakApiService } from "./key-cloak-api.service";
import { localStorageService, StorageKey } from "./local-storage.service";

class AuthService {

  async login(
    data: {username: string; password: string},
  ): Promise<LoginResponse> {
    const res = await keyCloakApiService.login(data);
    
    // set the token in local storage
     localStorageService.setItem(StorageKey.ACCESS_TOKEN, res.data.access_token);
     localStorageService.setItem(StorageKey.REFRESH_TOKEN, res.data.refresh_token);
    // decode token
    return this.decodeLoginJwt(res.data.access_token);
  }

  async getAuthenticatedUser(): Promise<LoginResponse | null> {
    const accessToken =  localStorageService.getItem(StorageKey.ACCESS_TOKEN);
    if (accessToken) {
      return this.decodeLoginJwt(accessToken);
    }

    return null;
  }

  private decodeLoginJwt(accessToken: string): LoginResponse {
    const jwtPayload = jwtDecode<LoginJwtPayload>(accessToken);
    return {
      firstName: jwtPayload.given_name,
      lastName: jwtPayload.family_name,
      email: jwtPayload.email,
      verified: jwtPayload.email_verified,
      userId: jwtPayload.sub
    };
  }

  async refreshLogin(refreshToken: string) {
    const res = await keyCloakApiService.refreshToken(refreshToken); 
     localStorageService.setItem(StorageKey.ACCESS_TOKEN, res.data.access_token);
     localStorageService.setItem(StorageKey.REFRESH_TOKEN, res.data.refresh_token);
  }

  }
  
  const authService = new AuthService();
  export { authService };