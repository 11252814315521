import React, { useState, ChangeEvent } from 'react';
import './catalogue-page.part.multiple-checkbox.scss';
import { LabelValue } from '../../../../types/label-value.types';



interface MultipleCheckboxProps {
  options: LabelValue[];
  value?: string[]; 
  onChange?: (values: string[]) => void; 
}

const CataloguePartMultipleCheckbox: React.FC<MultipleCheckboxProps> = ({
  options,
  value = [],
  onChange,
}) => {
  const [internalValues, setInternalValues] = useState<string[]>(value);

  const handleCheckboxChange = (optionValue: string) => {
    const newValue = internalValues.includes(optionValue)
      ? internalValues.filter(v => v !== optionValue)
      : [...internalValues, optionValue];

    setInternalValues(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="catalogue-side-menu-checkbox-container ">
      {options.map(option => (
        <div key={option.value} className="checkbox-item">
          <input
            id={`checkbox-${option.value}`}
            type="checkbox"
            checked={internalValues.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
            className="checkbox"
          />
          <label htmlFor={`checkbox-${option.value}`} className="checkbox-label">
            <span className="checkbox-checkmark">
              {internalValues.includes(option.value) && <span className="app-icon app-icon-check"></span>}
            </span>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CataloguePartMultipleCheckbox;
