import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardPurchaseType, GiftCardPurchaseModel, GiftCardTheme, GiftCardType, NotificationType } from '../models';
import { uuid } from '../utils/random.utils';
import { CustomCardValueFormValue } from '../router/public/pages/create-custom-design/custom-design.step-card-value';

const maleCardBgImg = require('../assets/images/card-bg/male-card-bg.png');


interface ShoppingCartCardItem {
    card: GiftCardPurchaseModel;
    purchaseInfo: CustomCardValueFormValue;
}

export interface ShoppingCartState {
    items: ({
        id: string;
    } & ShoppingCartCardItem)[];
}


const initialState: ShoppingCartState = {
    items: [{
        id: uuid(),
        purchaseInfo: {
            giftCardType: GiftCardType.PHYSICAL,
            value: 50000,
            quantity: 1,
            type: CardPurchaseType.PREPAID,
            // cardMessage: 'Dummy message',
            notificationType: NotificationType.EMAIL,
            notificationTo: 'Dest',
            notificationFrom: 'Source',
            notificationMessage: 'Happy BDay',
            notificationDateTime: '15/03/2024 15:30'
        },
        card: {
            type: CardPurchaseType.GIFT,
            theme: GiftCardTheme.DARK,
            backgroundImage: maleCardBgImg,
            text: 'A Gift For You',
            title: 'Visa Prépayée Internationale',
            priceRange: '50.000 - 500.000 FCFA',
        },
    }, {
        id: uuid(),
        purchaseInfo: {
            giftCardType: GiftCardType.VIRTUAL,
            value: 200000,
            quantity: 3,
            type: CardPurchaseType.PREPAID,
            // cardMessage: 'Dummy message',
            notificationType: NotificationType.EMAIL,
            notificationTo: 'Dest',
            notificationFrom: 'Source',
            notificationMessage: 'Happy BDay',
            notificationDateTime: '15/03/2024 15:30'
        },
        card: {
            type: CardPurchaseType.PREPAID,
            theme: GiftCardTheme.DARK,
            backgroundImage: maleCardBgImg,
            text: 'A Gift For You',
            title: 'Visa Cadeau Digitale',
            priceRange: '50.000 - 500.000 FCFA',
        },
    }]
};

const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        addItem: (state: ShoppingCartState, action: PayloadAction<ShoppingCartCardItem>) => {
            state.items.unshift({
                id: uuid(),
                ...action.payload
            });
        },
        removeItem: (state: ShoppingCartState, action: PayloadAction<string>) => {
            state.items = state.items.filter(item => item.id !== action.payload);
        },
        updateItem: (
            state: ShoppingCartState,
            action: PayloadAction<{ id: string; updates: Partial<ShoppingCartCardItem> }>
        ) => {
            const { id, updates } = action.payload;
            const itemIndex = state.items.findIndex(item => item.id === id);
            if (itemIndex !== -1) {
                state.items[itemIndex] = {
                    ...state.items[itemIndex],
                    ...updates,
                    purchaseInfo: {
                        ...state.items[itemIndex].purchaseInfo,
                        ...updates.purchaseInfo
                    },
                    card: {
                        ...state.items[itemIndex].card,
                        ...updates.card
                    },
                };
            }
        }
    }
});

export const shoppingCartReducer = shoppingCartSlice.reducer;
export const shoppingCartActions = {
    addItem: shoppingCartSlice.actions.addItem,
    removeItem: shoppingCartSlice.actions.removeItem,
    updateItem: shoppingCartSlice.actions.updateItem
};