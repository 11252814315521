import { apiService } from '../services/api.service';
import {
  ProcessId,
  processToInitialTaskNameMap,
  TaskName,
  taskToUrlMap
} from './process.types';
import { processSliceActions, ProcessState } from '../store/process.slice';
import { CompleteTaskRequestPayload, StartProcessRequestPayload } from '../services/api.service.types';
import { Dispatch } from '@reduxjs/toolkit';
import { appLogger } from '../services/logger.service';
import { timerService } from '../services/timer.service';

class ProcessService {
  /**
   * Start a new process and get the new job id
   */
  async startProcess<T extends ProcessId>(
    processId: T,
    data: StartProcessRequestPayload[T],
    dispatch: Dispatch<any>
  ): Promise<number> {
    // create and start process
    const result = await apiService.startProcess(processId, data);
    const jobId = result.data;

    // wait for process to go to the next task
    await this.waitForTaskUpdate(
      jobId,
      processToInitialTaskNameMap[processId]
    );

    // update the store
    dispatch(
      processSliceActions.startProcess({
        processId,
        jobId
      })
    );

    return jobId;
  }

  async startNextTask(
    jobId: number | undefined,
    navigate: (url: string) => unknown,
    dispatch: Dispatch<any>
  ): Promise<void> {
    if (!jobId) {
      appLogger.error('No process in progress');

      // flush the process
      dispatch(processSliceActions.endProcess());

      // redirect to home page
      await navigate('/');
      return;
    }

    // get the next task
    let result;
    try {
      result = await apiService.getActiveTask(jobId);
      appLogger.debug(result.data.task);
    } catch (e: any) {
      // #TODO TBD how to handle this kind of errors
      appLogger.error(e.response.data);
      throw e;
    }

    // check if the process is (still) active
    if (!result.data.active) {
      appLogger.error('Process not active');

      // flush the process
      dispatch(processSliceActions.endProcess());

      // redirect to home screen
      navigate('/');

      return;
    }

    // update the store
    dispatch(
      processSliceActions.startTask({
        taskName: result.data.task,
        humanTaskId: result.data.humanTaskId,
      })
    );

    // navigate to corresponding screen
    navigate(taskToUrlMap[result.data.task]);
  }

  async completeTask<T extends TaskName>(
    processState: ProcessState,
    data: CompleteTaskRequestPayload[T],
    navigate: (url: string) => unknown,
    dispatch: Dispatch<any>,
    finalStep?: true
  ): Promise<void> {
    if (!processState.inProgress?.task) {
      appLogger.error('No process or task in progress');

      // flush the process
      dispatch(processSliceActions.endProcess());

      // redirect to home page
      navigate('/');
      return;
    }

    await apiService.completeTask<T>(
      processState.inProgress.task.taskName as T,
      processState.inProgress.task.humanTaskId,
      data
    );

    dispatch(
      processSliceActions.completeTask()
    );

    if (!finalStep) {
      await this.waitForTaskUpdate(
        processState.inProgress.jobId,
        processState.inProgress.task.taskName
      );
    }
  }

  private async waitForTaskUpdate(
    jobId: number,
    currentTask: TaskName,
    waitTimeMs: number = 0
  ): Promise<boolean> {
    try {
      const result = await apiService.getActiveTask(jobId);
      if (
        result?.data?.task &&
        result.data.task !== currentTask
      ) {
        // task changed
        return true;
      }
    } catch (e) {
      // do nothing, wait for retry
    }

    // task not changed, increase waitTime and retry
    const newWaitTime = waitTimeMs > 0 ? (2 * waitTimeMs) : 100;
    if (newWaitTime > 3000) {
      // fail the operation after 3 seconds as something else might be wrong
      return false;
    }

    await timerService.wait(newWaitTime);

    return this.waitForTaskUpdate(
      jobId,
      currentTask,
      newWaitTime
    );
  }
}

const processService = new ProcessService();
export { processService };