import React, { ReactElement } from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import { ValidationRule, Message } from 'react-hook-form';
import './app-form-input.scss';
import AppCheckboxInput from './base/app-checkbox-input';

interface Validations {
  required: Message | ValidationRule<boolean>;
}

interface AppFormCheckboxInputProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  label: string;
  validations?: Partial<Validations>;
}

const AppFormCheckboxInput = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control, name, label, validations
}: AppFormCheckboxInputProps<TForm, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      render={({ field, fieldState }) => (
        <>
          <AppCheckboxInput
            id={name}
            label={label}
            checked={field.value}
            onChange={field.onChange}
          />
          <p className="form-element-error">{fieldState.error?.message || ' '}</p>
        </>
      )}
    />
  ) as ReactElement;
}

export default AppFormCheckboxInput;