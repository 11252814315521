import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyAccountPage } from '../router/my-account/my-account-menu';

export interface NavigationState {
  accountPageLoaded?: MyAccountPage;
}

const initialState: NavigationState = {};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setAccountPage: (state: NavigationState, action: PayloadAction<MyAccountPage>) => {
      state.accountPageLoaded = action.payload;
    },
    removeAccountPage: (state: NavigationState) => {
      state.accountPageLoaded = undefined;
    },
  }
});

export const navigationReducer = navigationSlice.reducer;
export const navigationSliceActions = {
  setAccountPage: navigationSlice.actions.setAccountPage,
  removeAccountPage: navigationSlice.actions.removeAccountPage,
};