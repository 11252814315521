import React, { useState } from 'react';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { processService } from '../../../../processes/process.service';
import { TaskName } from '../../../../processes/process.types';
import { ProcessState } from '../../../../store/process.slice';
import { kycSliceActions } from '../../../../store/kyc.slice';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';

const RegisterPageStepIdCard: React.FC = () => {
  const [img, setImg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const webcamRef = React.useRef<Webcam>(null);
  const processState = useSelector<AppStore, ProcessState>(store => store.process);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      // #TODO "mime" property will go away
      await processService.completeTask<TaskName.UPLOAD_ID>(processState, {
        idFile: {
          mime: '@file/jpeg',
          data: img as string
        }
      }, navigate, dispatch);

      // save the id card to the store
      dispatch(kycSliceActions.saveIdCard(img));

      await processService.startNextTask(processState.inProgress?.jobId, navigate, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const onSkipHandler = async () => {
    setIsLoading(true);

    try {
      await processService.completeTask<TaskName.UPLOAD_ID>(processState, {
        skipKyc: true
        }
      , navigate, dispatch);

      await processService.startNextTask(processState.inProgress?.jobId, navigate, dispatch);
    } finally {
      setIsLoading(false);
    }
  }

  const captureImg = () => {
    const imageBase64 = webcamRef.current?.getScreenshot();
    setImg(imageBase64 || null);
  };

  return (
    <>
      <AppFormSectionTitle
        title={t('pages.register.step.id-card.form.title')}
        info={t('pages.register.step.id-card.form.info')}
      ></AppFormSectionTitle>

      <div className="form-element-width flex-column flex-align-start-center flex-gap-5">
        {!img && (
          <>
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              style={{width: '100%', maxWidth: 450}}
              videoConstraints={{aspectRatio: (450/260)}}
            />
            <button onClick={captureImg}>{t('pages.register.step.id-card.button.capture-photo')}</button>
          </>
        )}

        {img && (
          <>
            <img
              src={img}
              style={{width: '100%', maxWidth: 450}}
              alt="identity card"
            />
            <button onClick={captureImg}>{t('pages.register.step.id-card.button.replace-photo')}</button>
          </>
        )}
      </div>

      <div className="form-buttons-container">
        <Button
          label={t('layout.buttons.next-step')}
          onClick={onSubmitHandler}
          theme="accent"
          disabled={!img}
        ></Button>

        <Button
          label={t('Skip for now')}
          onClick={onSkipHandler}
          theme="outline"
        ></Button>
      </div>
    </>
  );
};

export default RegisterPageStepIdCard;