import React, { useRef } from 'react';
import './app-checkbox-input.scss';

interface AppCheckboxInputProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const AppCheckboxInput: React.FC<AppCheckboxInputProps> = ({
  id, label, checked, onChange, className
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={`app-checkbox-container ${className}`}>
      <input
        id={id}
        ref={inputRef}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="app-checkbox"
      />
      <label htmlFor={id} className="app-checkbox-label">
        <span className="checkbox-checkmark">
          {checked && <span className="app-icon app-icon-check"></span>}
        </span>
        {label}
      </label>
    </div>
  );
};

export default AppCheckboxInput;
