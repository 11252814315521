import React from 'react';
import './app-form-section-title.scss';
import { useTranslation } from 'react-i18next';

interface AppFormSectionTitleProps {
  title: string;
  info?: string;
  required?: boolean;
  optional?: boolean;
}

const AppFormSectionTitle: React.FC<AppFormSectionTitleProps> = (
  {title, info, required, optional}
) => {
  const {t} = useTranslation();

  return (
    <div className="app-form-section-title">
      <div className="title">
        <h2>{title}</h2>

        {required && (
          <span className="text-required">
            &nbsp;(<span className="text-error">*</span>{t('forms.section-title.required-label')})
          </span>
        )}

        {optional && (
          <span className="text-optional">
            &nbsp;({t('forms.section-title.optional-label')})
          </span>
        )}
      </div>

      {
        info && (
          <p className="info">{info}</p>
        )
      }
    </div>
  );
};

export default AppFormSectionTitle;