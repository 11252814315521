import React from 'react';
import './home-page.part.custom-card-steps.scss';
import { useTranslation } from 'react-i18next';

const steps = [{
  imgSrc: require('../../../../assets/images/landing/custom-card-step-1.png'),
  title: 'pages.home.custom-card-steps-section.step1.title',
  text: 'pages.home.custom-card-steps-section.step1.text'
}, {
  imgSrc: require('../../../../assets/images/landing/custom-card-step-2.png'),
  title: 'pages.home.custom-card-steps-section.step2.title',
  text: 'pages.home.custom-card-steps-section.step2.text'
}, {
  imgSrc: require('../../../../assets/images/landing/custom-card-step-3.png'),
  title: 'pages.home.custom-card-steps-section.step3.title',
  text: 'pages.home.custom-card-steps-section.step3.text'
}];

const HomePagePartCustomCardSteps: React.FC = () => {
  const {t} = useTranslation();

  return (
    <div className="home-page-part-custom-card-steps">
      {steps.map((step, idx) => (
        <>
          <div className="step" key={idx}>
            <img src={step.imgSrc} alt="create a custom card" />
            <div className="title-text">
              <span className="title">{t(step.title)}</span>
              <span className="text">{t(step.text)}</span>
            </div>
          </div>

          {idx < (steps.length - 1) && (
            <div className="separator" key={`separator-${idx}`}></div>
          )}
        </>
      ))}
    </div>
  );
};

export default HomePagePartCustomCardSteps;