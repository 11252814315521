import React, { PropsWithChildren, useState } from 'react';
import './dashboard-form-section.scss';
import { useTranslation } from 'react-i18next';

interface DashboardFormSectionProps extends PropsWithChildren {
  title: string;
  onSave: () => Promise<void>;
  readonlyView?: React.ReactNode;
  editView?: React.ReactNode;
  formIsValid?: boolean;
}

const DashboardFormSection: React.FC<DashboardFormSectionProps> = (
  {title, onSave, children, readonlyView, editView, formIsValid}
) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const {t} = useTranslation();

  const onSaveHandler = async () => {
    try {
      await onSave();
      setIsEditing(false);
    } catch (e) {
      // do nothing;
    }
  };

  return (
    <div className="dashboard-form-section">
      <div className="dashboard-form-section-title">
        <h2>{title}</h2>

        {isEditing ? (
          <span
            className={`action-button ${!formIsValid ? 'disabled' : ''}`}
            onClick={() => formIsValid && onSaveHandler()}
          >
            <span>{t('layout.buttons.save-close')}</span>
            <span className="app-icon app-icon-check"></span>
          </span>
        ) : (
          <span
            className="action-button"
            onClick={() => setIsEditing(true)}
          >
            <span>{t('layout.buttons.edit')}</span>
            <span className="app-icon app-icon-edit"></span>
          </span>

        )}
      </div>

      {(isEditing ? editView : readonlyView) || children}
    </div>
  );
};

export default DashboardFormSection;