import React from 'react';
import './App.scss';
import AppRouter from './router/app-router';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './i18n/i18n';
import AppInit from './AppInit';

function App() {
  return (
    <>
      <Provider store={store}>
        <AppInit>
          <AppRouter />
        </AppInit>
      </Provider>
    </>
  );
}

export default App;
