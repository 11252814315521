import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import './app-layout.scss';
import CenteredSection from '../components/centered-section';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppStore } from '../store/store';
import { UserState } from '../store/user.slice';

const qrCodePlaceholder = require('../assets/images/qr-code-placeholder.png');
const groupPartners = require('../assets/images/footer-group-partners.png');
const groupPartnersMobile = require('../assets/images/footer-partners-mobile.png');


const AppLayout: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userState = useSelector<AppStore, UserState>(store => store.user);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigate = (path: string) => {
    if (userState.user?.id) {
      navigate(path)
    } else {
      navigate('/login')
    }
  }
  return (
    <div className="app-layout">
      <nav className="topnav flex-column">
        <CenteredSection className="section-heading">
          <div className="flex-row flex-align-center-center">
            <div className="logo flex-row flex-align-start-center">
              <div className="menu-trigger">
                <span className="app-icon app-icon-menu"></span>
              </div>
              <img onClick={() => navigate('/')} src="/bloom-logo.png" alt="Bloom logo" />
            </div>
            <div className="search-bar">
              <span className="app-icon app-icon-search"></span>
              <span className="search-placeholder flex-1">{t('layout.top-nav.search.placeholder')}</span>
              <span className="app-icon app-icon-close"></span>
            </div>
            <div className="user-menu flex-1 flex-row flex-align-end-center">
              <div className="user-menu-item" onClick={() => handleNavigate('/account/profile')}>
                <span className="app-icon app-icon-user"></span>
              </div>
              <div className="user-menu-item" onClick={() => handleNavigate('/account/profile')}>
                <span className="app-icon app-icon-bell"></span>
              </div>
              <div className="user-menu-item" onClick={() => handleNavigate('/account/profile')}>
                <span className="app-icon app-icon-shopping-bag"></span>
              </div>
              <div className="user-menu-item" onClick={() => handleNavigate('/account/profile')}>
                <span className="app-icon app-icon-wallet"></span>
              </div>
            </div>
          </div>
        </CenteredSection>
        <CenteredSection className="section-menu">
          <div className="flex-row flex-align-space-between-center">
            <div className="main-menu">
              <NavLink
                to="/e-gift-cards"
                className={({ isActive }) => isActive ? 'active' : undefined}
              >{t('layout.menu.item.buy-visa-prepaid')}</NavLink>
              <NavLink
                to="/e-gift-cards"
                className={({ isActive }) => isActive ? 'active' : undefined}
              >{t('layout.menu.item.buy-egift-cards')}</NavLink>
              <NavLink
                to="/custom-design"
                className={({ isActive }) => isActive ? 'active' : undefined}
              >Create Custom Design</NavLink>
            </div>
            <div className="social-menu flex-row flex-align-start-center flex-gap-20">
              <span className="text">{t('layout.social-menu.subtitle')}:</span>
              <span className="app-icon app-icon-facebook"></span>
              <span className="app-icon app-icon-instagram"></span>
              <span className="app-icon app-icon-tiktok"></span>
              <span className="app-icon app-icon-linkedin"></span>
            </div>
          </div>
        </CenteredSection>
      </nav>

      <div className="page-wrapper flex-column">
        <Outlet />
      </div>

      {/* <CenteredSection className="section-payment-options">
        <section className="flex-row flex-align-center-center flex-gap-10">
          <div>VISA</div>
          <div>mastercard</div>
          <div>PayPal</div>
        </section>
      </CenteredSection> */}

      <footer>
        <CenteredSection className="section-footer">
          <div className="flex-row flex-wrap flex-align-space-between-start flex-gap-100">
            <div className="flex-1 flex-column flex-align-space-between-start flex-gap-20" style={{ width: '260px' }}>
              <div className="logo header">
                <img src="/bloom-logo.png" alt="Bloom logo" />
              </div>
              <div>Reinventing the art of giving by enabling the creation,
                customization, purchase and instant sending of digital gift cards and Mastercard prepaid cards,
                integrating modern and secure payment solutions for a smooth and convenient user experience.
                The Bloom application is developed by Firstcom Global Payments SA
                and the Bloom Mastercard is issued by our banking partner XXX. </div>
            </div>
            <div className="flex-1 flex-column flex-align-space-between-start flex-gap-20" >
              <div className='header'>Our Company</div>
              <NavLink to="/assistance/about-us" className="flex-column flex-gap-7">
                {t('pages.assistance.about-us.title')}
              </NavLink>
              <div className="flex-column flex-gap-7">Firstcom Global Payments SA</div>
              <div className="flex-column flex-gap-7">Careers</div>
            </div>
            <div className="flex-1 flex-column flex-align-space-between-start flex-gap-20">
              <div className='header'>Assistance</div>
              <NavLink to="/assistance/how-it-works" className="flex-column flex-gap-7">
                {t('pages.assistance.how-it-works.title')}
              </NavLink>
              <NavLink to="/assistance/terms-and-conditions" className="flex-column flex-gap-7">
                {t('pages.assistance.terms-and-conditions.title')}
              </NavLink>
              <NavLink to="/assistance/privacy-policy" className="flex-column flex-gap-7">
                {t('pages.assistance.privacy-policy.title')}
              </NavLink>
              <NavLink to="/assistance/faq" className="flex-column flex-gap-7">
                {t('pages.assistance.faq.title')}
              </NavLink>
            </div>
            <div className="flex-1 flex-column flex-align-space-between-start flex-gap-40">
              <div className='header'>Contacts</div>
              <div className="flex-column flex-gap-7">hello@bloomafrica.net</div>
              <div className='flex-row flex-gap-20'>
                  <img  src={qrCodePlaceholder} alt="QR image" />
                  <div className='flex-column'>
                    <span className="text-join">Join US NOW</span>
                    <span className="text-download">Download The App</span>
                  </div>
              </div>
            </div>
          </div>
        </CenteredSection>

        <CenteredSection className="flex-align-center-center">
          <div className="group-partners"> 
            <img src={ screenWidth > 600 ? groupPartners : groupPartnersMobile} alt="Group Partners" />
          </div>
        </CenteredSection>

        <CenteredSection className="section-copyright flex-align-center-end">
          <p>© 2024 Bloom. All rights reserved.</p>
        </CenteredSection>
      </footer>
    </div>
  );
};

export default AppLayout;