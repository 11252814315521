import dayjs from 'dayjs';

class DateService {
  getYear(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${dateObj.getFullYear()}`;
  }

  getMonth(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${(dateObj.getMonth() + 1)}`.padStart(2, '0');
  }

  getDay(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${dateObj.getDate()}`.padStart(2, '0');
  }

  formatBirthDate(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateString = date instanceof Date ? date.toISOString() : date;
    return dayjs(dateString).format('YYYY-MM-DD');
  }

  getExpirationShort(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${this.getMonth(dateObj)}/${this.getYear(dateObj)}`;
  }
}

const dateService = new DateService();
export { dateService };