import React from 'react';
import './dashboard-page-title.scss';

interface DashboardPageTitleProps {
  text: string;
}

const DashboardPageTitle: React.FC<DashboardPageTitleProps> = (
  {text}
) => {
  return (
    <h1 className="dashboard-page-title">{text}</h1>
  );
};

export default DashboardPageTitle;