import React from 'react';
import './home-page.part.give-a-gift.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';

const giveAGiftBgImg = require('../../../../assets/images/landing/give-a-gift-bg.png');

const HomePagePartGiveAGift: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/custom-design');
  }

  return (
    <div className="home-page-part-give-a-gift">
      <div className="box-card">
        <div className="design">
          <img src={giveAGiftBgImg} alt="give a gift card" />
        </div>
        <div className="text">
          <span className="title">{t('pages.home.give-a-gift-section.title')}</span>
          <span className="subtitle">{t('pages.home.give-a-gift-section.subtitle-part-1')}<br/>{t('pages.home.give-a-gift-section.subtitle-part-2')}</span>
          <p className='helper'>{t('pages.home.give-a-gift-section.helper')}</p>
          <p className="info">{t('pages.home.give-a-gift-section.info')}</p>
          <div className="flex-row">
            <Button label={t('layout.buttons.customize-gift-card')} onClick={handleClick}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePagePartGiveAGift;