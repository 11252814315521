import { FieldValues, useForm } from "react-hook-form";
import { ForwardedProps } from "../../../../types/component-props.types";
import { useTranslation } from "react-i18next";
import './login-page.login-form.scss';
import AppFormSectionTitle from "../../../../components/form/app-form-section-title";
import AppFormInput from "../../../../components/form/app-form-input";
import Button from "../../../../components/button";

export interface LoginFormValue extends FieldValues {
    username: string;
    password: string;
}

interface LoginPageLoginFormProps extends ForwardedProps {
    onSubmit: (data: LoginFormValue) => void;
    isLoading?: boolean;
}

const LoginPageLoginForm: React.FC<LoginPageLoginFormProps> = (
    { onSubmit, isLoading }
) => {
    const { t } = useTranslation();
    const form = useForm<LoginFormValue>({
        mode: 'onTouched',
        defaultValues: {}
    })
    return <>
        <AppFormSectionTitle
            title={t('pages.login.form.title')}
            info={t('pages.login.form.info')}
            required={true}
        />
        <AppFormInput
            name="username"
            control={form.control}
            label={t('forms.field.email-or-phone.label')}
            validations={{ required: true }}
        />

        <AppFormInput
            name="password"
            control={form.control}
            label={t('forms.field.password.label')}
            validations={{ required: true }}
            type="password"
        />

        <div className="form-buttons-container row">
            <div className="reset-password-link">
                <p>Forgot your password?
                    <span> Reset It!</span>
                </p>
            </div>
            <Button
                label="Login"
                disabled={!form.formState.isValid || isLoading}
                onClick={form.handleSubmit(onSubmit)}
            ></Button>

        </div>
    </>
}

export default LoginPageLoginForm