import React, { ReactElement } from 'react';
import { Message } from 'react-hook-form/dist/types/errors';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import './app-form-date-picker.scss';
import AppFormElementLabel from './base/app-form-element-label';
import AppFormElementContainer from './base/app-form-element-container';
import DatePicker from 'react-date-picker';
import { Value } from 'react-date-picker/src/shared/types';
import { dateService } from '../../services/date.service';
import AppInput from './base/app-input';

interface Validations {
  required: Message | ValidationRule<boolean>;
}

interface AppFormDatePickerProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  validations?: Partial<Validations>;
}

// #TODO fix when navigating through the Form with Tab key
const AppFormDatePicker: <TForm extends FieldValues> (props: AppFormDatePickerProps<TForm>) => ReactElement = (
  {control, name, label, validations}
) => {
  const onChangeHandler = (field: any, value: Value) => {
    const valueSanitized = value ? (value as Date).toISOString() : null;
    field.onChange(valueSanitized);
  };

  return (
    <AppFormElementContainer className="app-form-date-picker">
      <AppFormElementLabel
        name={name}
        label={label}
        validations={validations}
      ></AppFormElementLabel>

      <Controller
        name={name}
        control={control}
        rules={validations}
        render={({field, fieldState, formState}) => (
          <>
            <div className="date-picker-field-container flex-row flex-align-center-center flex-gap-30">
              <AppInput
                readOnly={true}
                placeholder="Day"
                value={dateService.getDay(field.value)}
              />
              <AppInput
                readOnly={true}
                placeholder="Month"
                value={dateService.getMonth(field.value)}
              />
              <AppInput
                readOnly={true}
                placeholder="Year"
                value={dateService.getYear(field.value)}
              />

              <DatePicker
                onCalendarClose={field.onBlur}
                onChange={(value) => onChangeHandler(field, value)}
                value={field.value}
              />
            </div>

            <p className="form-element-error">{fieldState.error?.type || ' '}</p>
          </>
        )}
      />
    </AppFormElementContainer>
  );
}

export default AppFormDatePicker;
