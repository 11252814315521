import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AppFormSectionTitle from "../../../../components/form/app-form-section-title";
import PageTitle from "../../../../components/page-title";
import { ShoppingCartState, shoppingCartActions } from "../../../../store/shopping-card.slice";
import { AppStore } from "../../../../store/store";
import './checkout-page.scss'
import GiftCard from "../../../../components/gift-card";
import { formatPrice } from "../../../../utils/number.utils";
import AppFormCounterInput from "../../../../components/form/app-form-counter-input";
import { FieldValues, useForm } from "react-hook-form";
import LineSeparator from "../../../../components/line-separator";

export interface CustomCardValueFormValue extends FieldValues {
    quantity: number;
}

const CheckoutPage: React.FC = () => {
    const shoppingCartState = useSelector<AppStore, ShoppingCartState>(store => store.shoppingCart);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onRemoveItemHandler = (itemId: string) => {
        dispatch(shoppingCartActions.removeItem(itemId));
    };
    const form = useForm({
        mode: 'onTouched',
        defaultValues: shoppingCartState.items.reduce((acc, item) => {
            acc[`quantity_${item.id}`] = item.purchaseInfo.quantity;
            return acc;
        }, {} as Record<string, number>)
    });

    const handleQuantityChange = (id: string, quantity: number) => {
        const item = shoppingCartState.items.find(item => item.id === id);
        if (item) {
            dispatch(shoppingCartActions.updateItem({
                id,
                updates: {
                    purchaseInfo: {
                        ...item.purchaseInfo,
                        quantity,
                    }
                }
            }));
        }
    };

    return (
        <div className="page-outer">
            <div className="page-content">
                <PageTitle text={'Checkout'}></PageTitle>
                <div className="checkout-page-content-container">
                    <div className="details-container">
                        Send/Receive
                    </div>
                    <div className="items-container">
                        <div className="item-container">
                            <div className="quantity-container">
                                <h2></h2>
                            </div>
                            <AppFormSectionTitle
                                title="Items"
                                info="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac mi ut felis tincidunt dapibus."
                            />
                        </div>
                        {shoppingCartState.items.map((item) => (
                            <div className="item-container" key={item.id}>
                                <div className="quantity-container">
                                    <h2>{`${item.purchaseInfo.quantity}x`}</h2>
                                </div>
                                <div className="card-container">
                                    <GiftCard card={item.card} />
                                </div>
                                <div className="flex-column title-value-container">
                                    <span>{item.card.title}</span>
                                    <span className="value">{formatPrice(item.purchaseInfo.value! || item.purchaseInfo.customValue!)}</span>
                                </div>
                                    <AppFormCounterInput               
                                        control={form.control}
                                        name={`quantity_${item.id}`}
                                        min={1}
                                        max={100}
                                        step={1}
                                        onChange={(quantity) => handleQuantityChange(item.id, quantity)}
                                    />
                                    <div className="buttons-container">
                                        <span className="app-icon app-icon-heart"></span>
                                        <span className="app-icon app-icon-delete" style={{color: '#F15D60', cursor: 'pointer'}} onClick={() => onRemoveItemHandler(item.id)}></span>
                                    </div>
                            </div>
                        ))
                        }
                        <LineSeparator/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage;