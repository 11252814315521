import React, { ReactElement } from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import './app-form-input.scss';
import AppFileUploadInput from './base/app-upload-input';

interface Validations {
  required?: boolean;
}

interface AppFormUploadInputProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  label: string;
  validations?: Partial<Validations>;
  text?: string;
  boldedText?: string;
}

const AppFormUploadInput = <TForm extends FieldValues, TName extends FieldPath<TForm>>(
  {control, name, label, validations, text, boldedText}: AppFormUploadInputProps<TForm, TName>
) => {
  return (
      <Controller
        name={name}
        control={control}
        rules={validations}
        render={({ field, fieldState }) => (
          <>
            <AppFileUploadInput
              id={name}
              label={label}
              onChange={(e) => {
                const file = e.target.files?.[0] || null;
                if (file) {
                  const urlImage = URL.createObjectURL(file);
                  field.onChange(urlImage); 
                } else {
                  field.onChange(null); 
                }
              }}
              value={field.value}
              text={text}
              boldedText={boldedText}
            />
            <p className="form-element-error">{fieldState.error?.message || ' '}</p>
          </>
        )}
      />
  ) as ReactElement;
}

export default AppFormUploadInput;
