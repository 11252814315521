import React from 'react';
import { Control, Controller, FieldValues, FieldPath, ValidationRule } from 'react-hook-form';
import './app-form-dropdown.scss';
import Dropdown from './base/app-dropdown';
import { LabelValue } from '../../types/label-value.types';
import { Message } from 'react-hook-form/dist/types/errors';

interface Validations {
  required: Message | ValidationRule<boolean>;
}


interface AppFormDropdownProps<TForm extends FieldValues,TName extends FieldPath<TForm>, TValue extends (string | number)> {
  control: Control<TForm>;
  name: TName;
  options: LabelValue<TValue>[];
  placeholder?: string;
  validations?: Partial<Validations>;
  onChange?: (option: string) => void;
}

const AppFormDropdown = <TForm extends FieldValues, TName extends FieldPath<TForm>,TValue extends (string | number)>({
  control,
  name,
  options,
  placeholder,
}: AppFormDropdownProps<TForm, TName, TValue>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
        <Dropdown
          options={options}
          value={field?.value}
          onChange={field.onChange}
          placeholder={placeholder}
        />
      )}
      }
    />
  );
};

export default AppFormDropdown;
