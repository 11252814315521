import React from 'react';
import { RouteObject } from 'react-router/dist/lib/context';
import RegisterPageStepPersonalDetails from './register-page.step-personal-details';
import RegisterPageStepVerification from './register-page.step-verification';
import RegisterPageStepVerifyOtp from './register-page.step-verify-otp';
import RegisterPageStepAddress from './register-page.step-address';
import RegisterPageStepIdCard from './register-page.step-id-card';
import RegisterPageStepPassword from './register-page.step-password';
import RegisterPageStepSelfie from './register-page.step-selfie';

export const registerRouter: RouteObject[] = [
  {
    path: '',
    element: <RegisterPageStepPersonalDetails />
  },
  {
    path: 'verification',
    element: <RegisterPageStepVerification />
  },
  {
    path: 'verify-otp',
    element: <RegisterPageStepVerifyOtp />
  },
  {
    path: 'address',
    element: <RegisterPageStepAddress />
  },
  {
    path: 'upload-id',
    element: <RegisterPageStepIdCard />
  },
  {
    path: 'upload-selfie',
    element: <RegisterPageStepSelfie />
  },
  {
    path: 'set-password',
    element: <RegisterPageStepPassword />
  },
];
