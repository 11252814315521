import { RouteObject } from "react-router-dom";
import CustomDesignPageStepCustomize from "./custom-design.step-customize";
import CustomDesignPageStepCardValue from "./custom-design.step-card-value";

export const customDesignRouter: RouteObject[] = [
    {
        path: '',
        element: <CustomDesignPageStepCustomize/>
    },
    {
        path: 'card-value',
        element: <CustomDesignPageStepCardValue/>
    }
]; 