import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import AppFormInput from '../../../../components/form/app-form-input';
import AppFormDatePicker from '../../../../components/form/app-form-date-picker';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { registerSliceActions, RegisterState } from '../../../../store/register.slice';
import { useTranslation } from 'react-i18next';

export interface PersonalDetailsFormValue extends FieldValues {
  firstName: string;
  lastName: string;
  birthDate: string;
}

const RegisterPageStepPersonalDetails: React.FC = () => {
  const navigate = useNavigate();
  const registerState = useSelector<AppStore, RegisterState>(store => store.register);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const form = useForm<PersonalDetailsFormValue>({
    mode: 'onTouched',
    defaultValues: registerState.personalDetails || {}
  });

  const onSubmitHandler = (data: PersonalDetailsFormValue) => {
    dispatch(registerSliceActions.savePersonalDetails(data));
    navigate('/register/verification');
  };

  return (
    <>
      <AppFormSectionTitle
        title={t('pages.register.step.personal-details.form.title')}
        info={t('pages.register.step.personal-details.form.info')}
        required={true}
      ></AppFormSectionTitle>

      <AppFormInput
        name="firstName"
        control={form.control}
        label={t('forms.field.first-name.label')}
        validations={{required: true}}
      />

      <AppFormInput
        name="lastName"
        control={form.control}
        label={t('forms.field.last-name.label')}
        validations={{required: true}}
      />

      <AppFormDatePicker
        name="birthDate"
        control={form.control}
        label={t('forms.field.birth-date.label')}
        validations={{required: true}}
      />

      <div className="form-buttons-container">
        <Button
          label={t('layout.buttons.next-step')}
          onClick={form.handleSubmit(onSubmitHandler)}
          theme="accent"
          disabled={!form.formState.isValid}
        ></Button>
      </div>
    </>
  );
};

export default RegisterPageStepPersonalDetails;