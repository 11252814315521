import React, { useRef, useState, ChangeEvent } from 'react';
import './app-upload-input.scss';
import Button from '../../button';

interface AppFileUploadInputProps {
  id: string;
  label: string;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | null;
  text?: string;
  boldedText?: string;
}

const AppFileUploadInput: React.FC<AppFileUploadInputProps> = ({
  id,
  label,
  onChange,
  value,
  text,
  boldedText
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setFileName(file.name);
      onChange(e); 
    }
  };

  const handleRemoveFile = () => {
    setFileName(null);
    if (inputRef.current) {
      inputRef.current.value = ''; 
    }
    onChange({ target: { value: null } } as unknown as ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <div className="app-upload-container">
        <input
          id={id}
          ref={inputRef}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button
          className="upload-button"
          theme="outline"
          label={label}
          onClick={handleButtonClick}
        />
        <p className="upload-text">
          You can upload an image as: <span className="bolded-text">JPEG, PNG
          <br />10 MB Max </span> - Recommended Size: 1016x642px
        </p>
      </div>
      {value && (
        <div className="app-file-info">
          <p className="file-name">Uploaded file: {fileName}</p>
          <Button
            className="remove-file-button"
            theme="outline"
            label="Remove"
            onClick={handleRemoveFile}
          />
        </div>
      )}
    </>
  );
};

export default AppFileUploadInput;
