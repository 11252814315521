import React, { ReactElement } from 'react';
import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import { ValidationRule, Message } from 'react-hook-form';
import './app-form-textarea.scss';
import AppFormElementLabel from './base/app-form-element-label';
import AppFormElementContainer from './base/app-form-element-container';
import AppTextArea from './base/app-textarea';

interface Validations {
  required: Message | ValidationRule<boolean>;
  minLength: ValidationRule<number>;
  maxLength: ValidationRule<number>;
}

interface AppFormTextAreaProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  label: string;
  placeholder?: string;
  validations?: Partial<Validations>;
  rows?: number;
}

const AppFormTextArea = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  label,
  placeholder,
  validations,
  rows,
}: AppFormTextAreaProps<TForm, TName>) => {
  return (
    <AppFormElementContainer className="app-form-textarea">
      <AppFormElementLabel name={name} label={label} validations={validations} />
      <Controller
        name={name}
        control={control}
        rules={validations}
        render={({ field, fieldState }) => (
          <>
            <AppTextArea
              id={name}
              placeholder={placeholder}
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
              rows={rows}
            />
            <p className="form-element-error">{fieldState.error?.type || ' '}</p>
          </>
        )}
      />
    </AppFormElementContainer>
  ) as ReactElement;
};

export default AppFormTextArea;
