import { useState } from "react";
import { CardPurchaseType, GiftCardPurchaseModel, GiftCardTheme } from "../../../../models";
import SubtitleBicolor from "../../../../components/subtitle-bicolor";
import './catalogue-page.scss';
import CataloguePagePartShoppingOptions from "./catalogue-page.part.shopping-options-menu";
import GiftCardPurchase from "../../../../components/gift-card-purchase";
import { useTranslation } from "react-i18next";
import { customDesignSliceActions } from "../../../../store/custom-design.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";



const happyValentinesDayImg = require('../../../../assets/images/card-bg/valentines-logo.png');
const maleCardBgImg = require('../../../../assets/images/card-bg/male-card-bg.png');
const bossIconImg = require('../../../../assets/images/boss-icon.png');


const CataloguePage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch =  useDispatch();
    const navigate = useNavigate();
    const cardsPageMock: GiftCardPurchaseModel[] = [
        {
            theme: GiftCardTheme.ACCENT,
            backgroundImage: maleCardBgImg,
            title: t('pages.home.card-types-section.card-1.title'),
            text: 'A gift for you',
            type: CardPurchaseType.PREPAID
        },
        {
            theme: GiftCardTheme.DARK,
            backgroundColor: '#E06C19',
            iconImage: happyValentinesDayImg,
            title: t('pages.home.card-types-section.card-2.title'),
            priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            iconImage: bossIconImg,
            backgroundColor: '#304123',
            title: t('pages.home.card-types-section.card-2.title'), 
            priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            theme: GiftCardTheme.ACCENT,
            backgroundImage: maleCardBgImg,
            title: t('pages.home.card-types-section.card-1.title'),
            text: 'A gift for you',
            type: CardPurchaseType.PREPAID
        },
        {
            theme: GiftCardTheme.DARK,
            backgroundColor: '#E06C19',
            iconImage: happyValentinesDayImg,
            title: t('pages.home.card-types-section.card-2.title'),
            priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            iconImage: bossIconImg,
            backgroundColor: '#304123',
            title: t('pages.home.card-types-section.card-2.title'), priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            theme: GiftCardTheme.ACCENT,
            backgroundImage: maleCardBgImg,
            title: t('pages.home.card-types-section.card-1.title'),
            text: 'A gift for you',
            type: CardPurchaseType.PREPAID
        },
        {
            theme: GiftCardTheme.DARK,
            backgroundColor: '#E06C19',
            iconImage: happyValentinesDayImg,
            title: t('pages.home.card-types-section.card-2.title'),
            priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            iconImage: bossIconImg,
            backgroundColor: '#304123',
            title: t('pages.home.card-types-section.card-2.title'), priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            theme: GiftCardTheme.ACCENT,
            backgroundImage: maleCardBgImg,
            title: t('pages.home.card-types-section.card-1.title'),
            text: 'A gift for you',
            type: CardPurchaseType.PREPAID
        },
        {
            theme: GiftCardTheme.DARK,
            backgroundColor: '#E06C19',
            iconImage: happyValentinesDayImg,
            title: t('pages.home.card-types-section.card-2.title'),
            priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
        {
            iconImage: bossIconImg,
            backgroundColor: '#304123',
            title: t('pages.home.card-types-section.card-2.title'), priceRange: '50.000 - 500.000 FCFA',
            text: 'A gift for you',
            type: CardPurchaseType.GIFT
        },
       
    ];
    const [cards, setCards] = useState<GiftCardPurchaseModel[]>([...cardsPageMock]);

    const handleSelectCard = (card: GiftCardPurchaseModel) => {
        dispatch(customDesignSliceActions.saveTemplateDetails(card));
        if(card.iconImage == bossIconImg) {
            dispatch(customDesignSliceActions.saveCardValueDetails({merchant: 'boss'}));
        } else {
            dispatch(customDesignSliceActions.saveCardValueDetails({}));
        }
        navigate('/custom-design/card-value', {state: { cardType: card.type } });
    }

    return (
        <div className="catalogue-page">
            <div className="page-outer">
                <div className="flex-row flex-gap-10">
                    <div className="hide-md">
                    <CataloguePagePartShoppingOptions />
                    </div>
                    <div className="page-content flex-gap-40" style={{ alignItems: "flex-start", padding: '' }}>
                        <div className="flex-row" style={{alignSelf: 'center'}}>
                            <SubtitleBicolor leftText="Bloom" rightText="Cards" isHeader={true} />
                        </div>
                        <p className="info-text">Discover the range of Bloom cards available in our app, designed to meet all your payment needs. 
                            Two types of cards are available: Mastercard Prepaid Card (digital or physical): 
                            ideal for all your everyday transactions, whether for online payments, in-store, or cash withdrawals and Mastercard Digital Gift Card.
                        </p>
                        <div className="flex-row flex-gap-20 flex-wrap">
                            {cards.map((card, idx) => (
                                <div style={{ minWidth: '25%' }} key={idx}>
                                    <GiftCardPurchase card={card} useIconButton={true} onSelect={handleSelectCard}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CataloguePage;