import Button from '../../../../components/button';
import './home-page.part.preferred-brand.scss'


declare const require: {
    context: (path: string, deep?: boolean, filter?: RegExp) => any;
  };

  interface Brand {
    name: string,
    imgSrc: string
  }

const logos = require.context('../../../../assets/images/brands-logos/', true);
const logosList: Brand[] = logos.keys()
    .sort((a: string, b: string) => {
        const numA = parseInt(a.match(/\d+/)?.[0] || '0', 10);
        const numB = parseInt(b.match(/\d+/)?.[0] || '0', 10);
        return numA - numB;
    })
    .map((path: string) => {
        const name = path.match(/\/(\d+)-(.*?)\.png$/)?.[2] || '';
        return {
            name: name.replace(/-/g, ' '),  
            imgSrc: logos(path)
        };
    });



const HomePagePartPreferredBrands = () => {
    return (
        <div className="home-page-part-brands-container">
            <div className="brand-container">
            {
            logosList.map((brand) => (
            <div key={brand.name}>
                <img src={brand.imgSrc}  />
            </div>))
            }
            </div>
            <Button label="View All" theme='outline'></Button>
        </div>)
}

export default HomePagePartPreferredBrands;