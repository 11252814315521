import { useTranslation } from "react-i18next";
import { CardPurchaseType,  GiftCardPurchaseModel, GiftCardTheme } from "../../../../models";
import "./custom-design.step-customize.scss"
import SwitchToggle from "../../../../components/switch-toggle";
import LineSeparator from "../../../../components/line-separator";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import AppFormRadioGroup from "../../../../components/form/app-form-radio-group";
import AppFormUploadInput from "../../../../components/form/app-form-upload-input";
import AppFormColorSelector, { SelectColorType } from "../../../../components/form/app-form-color-selector";
import AppFormDropdown from "../../../../components/form/app-form-dropdown";
import AppFormCardTemplateSelector from "../../../../components/form/app-form-card-template-selector";
import { useEffect, useState } from "react";
import AppFormCheckboxInput from "../../../../components/form/app-form-checkbox-input";
import AppFormIconSelector from "../../../../components/form/app-form-icon-selector";
import GiftCard from "../../../../components/gift-card";
import Button from "../../../../components/button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customDesignSliceActions } from "../../../../store/custom-design.slice";




const colors: SelectColorType[] = [
  { color: 'black', value: '#000000' },
  { color: 'red', value: '#C50000' },
  { color: 'orange', value: '#FF7A00' },
  { color: 'yellow', value: '#FFC700' },
  { color: 'green', value: '#007661' },
  { color: 'darkBlue', value: '#0041C0' },
  { color: 'lightBlue', value: '#00B3FF' },
  { color: 'violet', value: '#8D00A4' },
  { color: 'lightGrey', value: '#E1E1E1' },
  { color: 'darkGrey', value: '#727272' },
];

enum ImageType {
  FULL = 'FULL',
  COLOR = 'COLOR'
}


export enum SwitchToggleElements {
  UPLOAD = 'Upload Image',
  SELECT = 'Select Color & Template'
}

export interface TemplateCategory {
  value: string;
  label: string;
}
const dropdownOptions: TemplateCategory[] = [
  { value: 'anniversary', label: 'Anniversary' },
  { value: 'baby', label: 'Baby' },
  { value: 'birthday', label: 'Birthday' },
  { value: 'business', label: 'Business' },
  { value: 'charity', label: 'Charity' },
  { value: 'congratulations', label: 'Congratulations' },
  { value: 'fathersDay', label: "Father's Day" },
  { value: 'graduation', label: 'Graduation' },
  { value: 'health', label: 'Health & Wellness' },
  { value: 'hobbies', label: 'Hobbies & Sports' },
  { value: 'housewarming', label: 'Housewarming' },
  { value: 'mothersDay', label: "Mother's Day" },
  { value: 'pets', label: 'Pets' },
  { value: 'thankYou', label: 'Thank You' },
  { value: 'travel', label: "Travel & Food" },
  { value: 'valentinesDay', label: "Valentine's Day" },
  { value: 'wedding', label: 'Wedding & Engagement' },
  { value: 'easter', label: "Easter" },
  { value: 'holiday', label: 'Holiday' },
];


interface CustomCardFormValue extends FieldValues {
  uploadedImage?: string;
  checkTermsAndConditions: boolean;
  color?: SelectColorType;
  selectCategory?: string;
  template?: string;
}

const CustomDesignPageStepCustomize = () => {
  const [activeToggle, setActiveToggle] = useState<SwitchToggleElements>(SwitchToggleElements.UPLOAD);
  const [customCard, setCustomCard] = useState<GiftCardPurchaseModel>({
    theme: GiftCardTheme.DARK, 
    backgroundColor: '#ECECEC',
    backgroundImage: "",
    title: "E-Gift for Merchants",
    type: CardPurchaseType.CUSTOM
  });

  const form = useForm<CustomCardFormValue>({
    mode: 'onTouched',
    defaultValues: {
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedCategory = useWatch({
    control: form.control,
    name: 'selectCategory',
  });

  const uploadedImage = useWatch({
    control: form.control,
    name: 'uploadedImage',
  });

  const color = useWatch({
    control: form.control,
    name: 'color',
  });

  const imageType = useWatch({
    control: form.control,
    name: 'imageType'
  })

  const template = useWatch({
    control: form.control,
    name: 'template'
  })

  const { t } = useTranslation();

  const views = [{
    type: SwitchToggleElements.UPLOAD,
    name: "Upload Image",
  }, {
    type: SwitchToggleElements.SELECT,
    name: "Select Color & Template",
  }];
  useEffect(() => {
    if (uploadedImage) {
      setCustomCard((prevCard) => ({
        ...prevCard,
        backgroundImage: uploadedImage,
      }));
    } else {
      setCustomCard((prevCard) => ({
        ...prevCard,
        backgroundImage: "",
      }));
    }
  }, [uploadedImage]);



  useEffect(() => {
    if (imageType === ImageType.FULL) {
      form.reset({
        ...form.getValues(),
        color: undefined,
        template: undefined,
        icon: undefined
      })
      setCustomCard({
        theme: GiftCardTheme.DARK,
        backgroundColor: '#E1E1E1',
        title: "E-Gift for Merchants",
        type: CardPurchaseType.CUSTOM
      });
    } else {
      form.reset({
        ...form.getValues(),
        color: colors[8],
        template: undefined,
        icon: undefined
      })
      setCustomCard({
        theme: GiftCardTheme.DARK,
        backgroundColor: color?.value,
        title: "E-Gift for Merchants",
        type: CardPurchaseType.CUSTOM
      });
    }
  }, [imageType])

  useEffect(() => {
    if (color) {
      setCustomCard((prevCard) => ({
        ...prevCard,
        backgroundColor: color.value,
      }));
    }
  }, [color]);

  useEffect(() => {
    if (template) {
      if (imageType === ImageType.FULL) {
        setCustomCard((prevCard) => ({
          ...prevCard,
          backgroundImage: template,
        }));
      }
    }
  }, [template]);


  const changeToggle = (value: SwitchToggleElements) => {
    setActiveToggle(value);

    if (value === SwitchToggleElements.UPLOAD) {

      form.reset({});
      setCustomCard({
        theme: GiftCardTheme.DARK, 
        backgroundColor: '#ECECEC',
        title: "E-Gift for Merchants",
        type: CardPurchaseType.CUSTOM
      });
    } else {
      form.reset({
        color: colors[8],
        imageType: ImageType.COLOR,
        selectCategory: dropdownOptions[1].value
      });
      setCustomCard({
        theme: GiftCardTheme.DARK, backgroundColor: color?.value,
        backgroundImage: "",
         title: "E-Gift for Merchants",
        type: CardPurchaseType.CUSTOM
      });
    }
  }

  const onSubmitHandler = () => {
    dispatch(customDesignSliceActions.saveTemplateDetails({...customCard}));
    navigate('/custom-design/card-value');
  };

  
  return (<>
    <div className="custom-design-item section1" style={{alignItems: "center"}} >
      <GiftCard card={customCard} customizeType={activeToggle} setValue={form.setValue} />
    </div>
    <div className="custom-design-item section2">
      <p>
        {t('pages.custom-design.step.customize.switch-toggle.title')}
      </p>
      <SwitchToggle elements={views} activeElement={activeToggle} onChange={changeToggle}></SwitchToggle>
      {activeToggle === SwitchToggleElements.UPLOAD ? <>
        <AppFormUploadInput
          control={form.control}
          name="uploadedImage"
          label={t('pages.custom-design.step.customize.form.upload-image.label')}
          text={t('')}
          boldedText={t('')}
        />
        <AppFormCheckboxInput
          control={form.control}
          name="checkTermsAndConditions"
          label={t('pages.custom-design.step.customize.form.terms.label')}
          validations={{ required: true }}
        />
        <LineSeparator className="separator-color" />
      </>
        : (<>
          <p>
            {t('pages.custom-design.step.customize.form.image-type.title')}
          </p>

          <AppFormRadioGroup
            name="imageType"
            control={form.control}
            validations={{ required: true }}
            options={[{
              value: ImageType.FULL,
              text: t('pages.custom-design.step.customize.form.image-type.text1'),
              secondaryText: t('pages.custom-design.step.customize.form.image-type.secondary-text1')
            }, {
              value: ImageType.COLOR,
              text: t('pages.custom-design.step.customize.form.image-type.text2'),
              secondaryText: t('pages.custom-design.step.customize.form.image-type.secondary-text2')
            }]}
          />

          {imageType === ImageType.COLOR ? (<>
            <p>
              {t('pages.custom-design.step.customize.form.color.title')}
            </p>
            <AppFormColorSelector name="color" control={form.control} options={colors} />
          </>) : 
          <>
           <div className="templates-icons-container">
            <p>{t('pages.custom-design.step.customize.form.selectCategory.title')}</p>
            <AppFormDropdown
              control={form.control}
              name="selectCategory"
              options={dropdownOptions}
            />
          </div>
          {selectedCategory ? (
            <AppFormCardTemplateSelector
              control={form.control}
              name="template"
              selectedCategory={selectedCategory}
            />
          ) : <></>}
          </> }
        </>)}
      <div className="button-container">
        <Button
          label="Next Step"
          disabled={!form.formState.isValid}
          onClick={form.handleSubmit(onSubmitHandler)}
        />
      </div>
    </div>
  </>
  )
}

export default CustomDesignPageStepCustomize

