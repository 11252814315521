import './gift-card-purchase.scss';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { GiftCardPurchaseModel } from '../models';
import GiftCard from './gift-card';
import Button from './button';
import ButtonIcon from './button-icon';

interface GiftCardPurchaseProps {
  card: GiftCardPurchaseModel;
  useIconButton?: boolean;
  onSelect?: (card: GiftCardPurchaseModel) => void;
}

const GiftCardPurchase: React.FC<GiftCardPurchaseProps> = (
  {card, useIconButton, onSelect}
) => {
  const {t} = useTranslation();

  return (
    <div className="gift-card-purchase flex-column flex-gap-30">
      <div onClick={() => {
        if(onSelect)
        onSelect(card)}
        }>
      <GiftCard card={card} />
      </div>
      <div className="purchase-card flex-row flex-align-space-between-center">
        <div className="flex-column flex-gap-4">
          <span className="card-purchase-name">{card.title}</span>
          <span className="card-purchase-price">{card.priceRange}</span>
        </div>
        {useIconButton ? <ButtonIcon icon="add-to-cart" onClick={() => {
        if(onSelect)
        onSelect(card)}
        }/> : <Button label={t('layout.buttons.buy-now')} onClick={() => {
          if(onSelect)
          onSelect(card)}
          }/>}
      </div>
    </div>
  );
};

export default GiftCardPurchase;
