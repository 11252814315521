import React, { PropsWithChildren } from 'react';
import './centered-section.scss';
import { ForwardedProps } from '../types/component-props.types';

const CenteredSection: React.FC<PropsWithChildren<ForwardedProps>> = ({children, className}) => {
  return (
    <div className={'centered-section flex-row flex-align-center-center ' + (className || '')}>
      {children}
    </div>
  );
};

export default CenteredSection;