import React, { PropsWithChildren } from 'react';
import './content-box.scss';
import { ForwardedProps } from '../types/component-props.types';

const ContentBox: React.FC<PropsWithChildren<ForwardedProps>> = ({children, className}) => {
  return (
    <div className={'content-box flex-column flex-align-start-center ' + (className || '')}>
      {children}
    </div>
  );
};

export default ContentBox;