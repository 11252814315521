import React from 'react';
import './card-details-readonly.scss';
import { useTranslation } from 'react-i18next';
import { dateService } from '../services/date.service';
import { UserBankCard } from '../models';
const cardIconImg = require('../assets/images/card-icon.png');

interface CardDetailsReadonlyProps {
  card: UserBankCard;
}

const CardDetailsReadonly: React.FC<CardDetailsReadonlyProps> = (
  {card}
) => {
  const {t} = useTranslation();

  return (
    <div className="card-details-readonly">
      <div className="flex-row flex-align-space-between-center">
        <span className="card-holder">
          <span className="label">{t('sections.cards.card-holder-label')}</span>: {card.card.name}
        </span>
        <span className={`card-priority ${card.primary ? 'primary' : ''}`}>
          {t(card.primary ? 'sections.cards.primary-label' : 'sections.cards.secondary-label')}
        </span>
      </div>
      <div className="card-info flex-row flex-align-start-center flex-gap-25">
        <img className="card-icon" src={cardIconImg} alt="card" />
        <div className="flex-1 flex-column flex-align-center-start flex-gap-5">
          <span className="label">{card.card.type}</span>
          <span className="value">**** **** **** {card.card.number.slice(-4)}</span>
        </div>
        <div className="flex-column flex-align-center-start flex-gap-5">
          <span className="label">{t('sections.cards.expiry-label')}</span>
          <span className="value">{dateService.getExpirationShort(card.card.expiry)}</span>
        </div>
      </div>
    </div>
  );
}

export default CardDetailsReadonly;
