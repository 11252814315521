export interface AddressModel {
  addressLine1: string;
  addressLine2?: string;
  city:	string;
  country:	string;
  postalCode:	string;
  latitude: number;
  longitude: number;
}

export class AddressModelClass {
  static getAddressString(address: AddressModel) {
    const parts = [address.addressLine1];
    if (address.addressLine2) {
      parts.push(address.addressLine2);
    }
    parts.push(address.city, address.country);
    return parts.join(', ');
  }
}