export enum BankCardType {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD'
}

export interface BankCardModel {
  name: string;
  number: string;
  expiry: string;
  type: BankCardType;
}

export interface UserBankCard {
  primary?: boolean;
  card: BankCardModel;
}